import { ListItemProps } from '@mui/material'
import { ListItemWrapper } from './styled'

interface Props extends ListItemProps {
    bold?: boolean;
}

export function ListItem({ bold, onClick, ...props }: Props) {
    return <ListItemWrapper {...props} $bold={bold} onClick={onClick} $withAction={!!onClick} />
}
