import React from 'react'
import { Circle, TransactionTypeItemWrapper } from './styled';
import { IconArrowDown, IconArrowUp } from '../../../../components/icons';
import { TransferType } from '../../types';

interface TransactionTypeItemProps {
    type: TransferType;
}

export function TransactionTypeItem({ type }: TransactionTypeItemProps) {
    return (
        <TransactionTypeItemWrapper>
            {type === TransferType.CREDIT && <Circle style={{background: 'var(--green10)'}} ><IconArrowDown color='var(--green600)' /></Circle>}
            {type === TransferType.DEBIT && <Circle style={{background: 'var(--red10)'}} ><IconArrowUp color='var(--red600)' /></Circle>}
            <div>
                {type === TransferType.CREDIT && "Received"}
                {type === TransferType.DEBIT && "Sent"}
            </div>
        </TransactionTypeItemWrapper>
    )
}
