import React, { useState } from 'react'
import { BalanceAmount, BalanceWrapper, Flex, Subtitle, Title } from './styled'
import { IconButton } from '../../../components/button'
import { IconEye, IconEyeClose } from '../../../components/icons'
import { Area, AreaChart, ResponsiveContainer } from 'recharts'

export function BalanceItem({amount}: {amount: number}) {
  const [scrumbleAmount, setScrumbleAmount] = useState(false);
  const data = [
    {
      name: 'Page E',
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: 'Page A',
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: 'Page B',
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: 'Page C',
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: 'Page D',
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: 'Page E',
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: 'Page F',
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: 'Page G',
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];
  return (
    <BalanceWrapper>
      <Flex style={{ justifyContent: "space-between" }}>
        <div>
          <Title>Sales amount</Title>
          <Subtitle>
            Overall total sales amount.
          </Subtitle>
        </div>
        <Flex style={{ gap: 'var(--padding)', alignItems: "flex-end" }}>
          <Flex style={{ width: 170, height: 100 }}>
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart
                data={data}
              >
                <defs>
                  <linearGradient id="balcolorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="var(--dark300)" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="var(--dark300)" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <Area type="monotone" dataKey="uv" stroke="var(--dark400)" strokeWidth={4} fillOpacity={1} fill="url(#balcolorUv)" />
              </AreaChart>
            </ResponsiveContainer>
          </Flex>
        </Flex>
      </Flex>
      <Flex style={{ alignItems: "flex-end", justifyContent: "space-between" }}>
        <div>
          <IconButton onClick={() => setScrumbleAmount(!scrumbleAmount)}>{scrumbleAmount ? <IconEyeClose /> : <IconEye />}</IconButton>
          <BalanceAmount>
            {scrumbleAmount ? '**********' : `GHC ${(amount || 0).toLocaleString()}`}
          </BalanceAmount>
        </div>
        {/* <Button startIcon={<IconWithdraw />}>Withdraw</Button> */}
      </Flex>
    </BalanceWrapper>
  )
}
