import { TransferLog } from "../../types";
import { useAccount } from "../../../auth/hooks";
import { transactionsApi } from "../../../../utils/api";
import { useTableQuery } from "../../../../utils/hooks/useTableQuery";

export function useTransactions(appId?: string) {
    const { user_id } = useAccount()
    const options = useTableQuery<TransferLog>({
        query: transactionsApi.getAll + '/' + user_id,
        parameters: { app_id: appId },
        queryId: ["get-transactions" + user_id, "get-transactions" + appId],
        enabled: !!user_id
    })
    return options;
}