import React from 'react'
import { Shimmer } from '../../../components/Shimmer'
import { AppsContent } from '../styled'

export function AppsPageShimmer() {
    return (
        <AppsContent>
            <Shimmer backgroundHeight='40000px' height='150px' width='150px' />
            <Shimmer backgroundHeight='40000px' height='150px' width='150px' />
            <Shimmer backgroundHeight='40000px' height='150px' width='150px' />
            <Shimmer backgroundHeight='40000px' height='150px' width='150px' />
            <Shimmer backgroundHeight='40000px' height='150px' width='150px' />
        </AppsContent>
    )
}
