import { Segmented } from "antd";
import styled from "styled-components";

export const SegmentedWrapper = styled(Segmented)`
    background: var(--dark500);
    color: var(--color);
    user-select: none;
    border-radius: 8px;
    margin-bottom: 0 !important;

    .ant-segmented-thumb {
        background: var(--dark400);
    }

    label {
        /* font-family: bold; */
        color: var(--secondaryColor);
    }

    .ant-segmented-item {
        border-radius: 6px !important;
    }

    label.ant-segmented-item-selected {
        background: var(--dark400);
        color: var(--color);
    }

    label:hover {
        color: var(--gray100) !important;
        background: var(--gray600);
    }

    label:hover.ant-segmented-item-selected {
        color: var(--color) !important;
        background: var(--dark400);
    }
    .ant-segmented-group{
        gap: 2px;
    }
`;