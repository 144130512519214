/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react'
import { Segmented } from '../../components/segmented'
import { Content } from '../../components/content'
import { IconButton } from '../../components/button'
import { IconSetting } from '../../components/icons'
import { Shimmer } from '../../components/Shimmer'
import useNav from '../../utils/hooks/useNav'
import { routes } from '../../utils/routes'
import { useApp } from './hooks'
import { DataResponseView } from '../../components/DataResponseView'
import { AppDetailsShimmer } from './components/AppDetailsShimmer'
import { generatePath, Outlet, useLocation } from 'react-router-dom'
import { AppDetailsOutletContext, AppDetailTab, InAppTypeEnum } from './types'

export function AppDetailsPage() {
    const { pathname } = useLocation();
    const split = pathname.split('/');
    const tabName = split?.[(split?.length || 0) - 1]
    const inTabs = ["Details", "Metrics", "Deployment", "Settings"].includes(tabName);

    const [selectedPage, setSelectedPage] = useState<AppDetailTab>(!tabName || !inTabs ? "Details" : tabName as any);
    const { navigate, params } = useNav();
    const appId = params?.id;
    const { data, isLoading, error, refetch } = useApp(appId!);

    const onChangeTab = (value: any) => {
        setSelectedPage(value);
        navigate(generatePath(routes.apps.details.root, { id: appId }) + `/${value === "Details" ? '' : value}`);
    }

    return (
        <Shimmer.SmoothTransition>
            <Content
                title='App details'
                showBackButton
                onGoBack={() => navigate(routes.apps.root)}
            >
                <Content.Toolbar>
                    <Segmented
                        value={selectedPage}
                        style={{ marginBottom: 8 }}
                        onChange={onChangeTab}
                        options={data?.type === InAppTypeEnum.NATIVE ? ['Details', "Template", "Metrics", 'Deployment'] : ['Details', "Metrics", 'Deployment']}
                    />
                    <IconButton
                        isActive={selectedPage === "Settings"}
                        onClick={() => onChangeTab("Settings")}
                    >
                        <IconSetting />
                    </IconButton>
                </Content.Toolbar>
                <Content.Main>
                    <DataResponseView
                        content={
                            <Outlet context={{ app: data, appId, inAppStatus: data?.status, onChangeTab, goBack: () => setSelectedPage("Details"), refetch } as AppDetailsOutletContext} />
                        }
                        data={data}
                        error={error}
                        isLoading={isLoading}
                        customLoadingComponent={<AppDetailsShimmer />}
                    />
                </Content.Main>
            </Content>
        </Shimmer.SmoothTransition>
    )
}
