import { Progress, UploadFile } from 'antd';
import React, { useCallback, useEffect, useState } from 'react'
import { IconButton } from '../button';
import { IconClose, IconEye } from '../icons';
import styled from 'styled-components';
import { useUploadFiles } from '../../utils/hooks/useUploadFiles';
import { useMultiUploadedFiles } from './MultiUpload';

interface Props {
    appId: string;
    file: UploadFile<any>,
    fileList: Array<UploadFile<any>>,
    actions: {
        download: () => void;
        preview: () => void;
        remove: () => void;
    }
}

function MUploadedItem({ actions, file, appId, fileList }: Props) {
    const [previewImageUrl, setPreviewImageUrl] = useState<string>();
    const { upload, isUploading, progress, url, } = useUploadFiles();
    const { setFile } = useMultiUploadedFiles();

    const onPreview = () => {
        if (!previewImageUrl) return;
        const image = new Image();
        image.src = previewImageUrl;
        const imgWindow = window.open(previewImageUrl);
        imgWindow?.document.write(image.outerHTML);
    }

    const onUpload = useCallback(
        () => {
            if (!file?.originFileObj) return;
            upload(file.originFileObj, appId, "products")
        },
        [file, appId, upload],
    )

    const preview = async (file: UploadFile) => {
        let src = file.url as string;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj as File);
                reader.onload = () => resolve(reader.result as string);
            });
        }
        setPreviewImageUrl(src)
    };

    useEffect(() => {
        console.log(file.name, "isUploading")
        if (file?.name) {
            onUpload()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [file])

    useEffect(() => {
        preview(file)
    }, [file])

    useEffect(() => {
        if(url) setFile(url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url])

    return (
        <Wrapper>
            <img alt={file.name} src={previewImageUrl} />
            {
                isUploading ?
                    <Overlay className='upload-item-loading__overlay'>
                        <ProgressWrapper percent={progress} />
                    </Overlay>
                    :
                    <Overlay className='upload-item__overlay'>
                        <IconButton onClick={actions.remove}><IconClose /></IconButton>
                        <IconButton onClick={onPreview}><IconEye /></IconButton>
                    </Overlay>
            }
        </Wrapper>
    )
}

export const UploadedItem = React.memo(MUploadedItem) as typeof MUploadedItem;

const Wrapper = styled.div`
    width: 120px;
    height: 120px;
    border-radius: 22px;
    border: 1px solid var(--gray700);
    padding: 4px;
    overflow: hidden;
    position: relative;
    transition: all 0.3s;
    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 18px;
    }
    .upload-item__overlay {
        display: none;
    }
    &:hover {
        .upload-item__overlay {
            display: flex;
        }
    }
`;

const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;
`;

const ProgressWrapper = styled(Progress)`
       width: 100px;
   .ant-progress-outer {
    flex-direction: column;
    gap: 5px;
    font-family: bold;
   }
`;