import styled from "styled-components";

export const TransactionTypeItemWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: var(--fontSmall);
`;

export const Circle = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const AmountCellWrapper = styled.div`
    display: flex;
    flex-direction: column;
    font-size: var(--fontRegular);
`;

export const AmountCellTitle = styled.div`
    font-family: bold;
`;

export const AmountCellSubtitle = styled.div`
    color: var(--secondaryColor);
    font-size: var(--fontSmall);
`;

export const StatusCellWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: var(--fontSmall);
    text-transform: capitalize;
    border-radius: 100px;
    padding: 2px 8px;
    width: fit-content;
`;

export const CustomerCellWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

export const CustomerCellContent = styled.div`
`;

export const CustomerCellTtitle = styled.div`
    font-family: bold;
`;

export const CustomerCellSubtitle = styled.div`
    color: var(--secondaryColor);
    font-size: var(--fontSmall);
`;

export const IDCellWrapper = styled.div`
    color: var(--blue);
    font-family: bold;
    cursor: pointer;

    &:hover {
        color: var(--blue600);
    }
`;