import React, { useMemo } from 'react'
import { InAppStatus } from '../types'
import { Tag } from 'antd'

export default function AppStatus({ status }: { status: InAppStatus }) {
    const { bg, color, label } = useMemo(() => {
        switch (status) {
            case InAppStatus.DEV_MODE:
                return {
                    label: "In development",
                    color: 'var(--yellow600)',
                    bg: 'var(--yellow10)',
                }
            case InAppStatus.REVIEW_PENDING:
                return {
                    label: "In review",
                    color: 'var(--blue)',
                    bg: 'var(--blue10)',
                }
            case InAppStatus.PUBLISHED:
                return {
                    label: "Published",
                    color: 'var(--green600)',
                    bg: 'var(--green10)',
                }
            case InAppStatus.SUSPENDED:
                return {
                    label: "Suspended",
                    color: 'var(--red600)',
                    bg: 'var(--red10)',
                }
            default:
                return {}
        }
    }, [status])
    return (
        <Tag style={{ background: bg, color, width: "max-content", borderRadius: 8, border: `1px solid ${color}` }} color={color}>{label}</Tag>
    )
}
