import styled from "styled-components";

export const ContentWrapper = styled.div`
    width: var(--contentWidth);
    margin: auto;
`;

export const ContentHeader = styled.div`
    display: flex;
    align-items: center;
    height: 94px;
    justify-content: space-between;
    position: sticky;
    top: 0px;
    z-index: 10;
    background: var(--dark600);
`;

export const ContentHeaderTitle = styled.div`
    font-size: 32px;
    font-family: extreme-bold;
`;

export const ContentHeaderActions = styled.div`
    display: flex;
    align-items: center;
    gap: var(--padding);
`;