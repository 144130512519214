import React from 'react'
import { Content } from '../../../components/content'
import { Button } from '../../../components/button'
import { DataResponseView } from '../../../components/DataResponseView'
import { IconPlus } from '../../../components/icons'
import { useAppDetailsContext } from '../hooks/useAppDetailsContext'
import useNav from '../../../utils/hooks/useNav'
import { routes } from '../../../utils/routes'

export function CategoriesPage() {
    const { app } = useAppDetailsContext();
    const {navigate, generatePath} = useNav();
    return (
        <Content title='Categories' nested>
            <Content.Toolbar>
                <Button onClick={()=> navigate(generatePath(routes.apps.details.template.createCategory, { id: app?.app_id }))} startIcon={<IconPlus />}>Add category</Button>
                </Content.Toolbar>
            <Content.Main>
                <DataResponseView
                    content={<></>}
                    data={[]}
                    error={false}
                    isLoading={false}
                />
            </Content.Main>
        </Content>
    )
}
