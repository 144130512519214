import React, { useEffect, } from 'react';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Avatar, message } from 'antd';
import { ImageUploadContent, ImageUploadWrapper } from './styled';
import { UploadFileStatus, useUploadFiles } from '../../utils/hooks/useUploadFiles';
import { UploadChangeParam, UploadFile } from 'antd/es/upload';
import ImgCrop from 'antd-img-crop';
import { IconClose } from '../icons';

type FileType = File

interface Props {
  imageUrl?: string;
  appId: string;
  setImageUrl: React.Dispatch<React.SetStateAction<string | undefined>>
}

export function ImageUpload({ imageUrl, appId, setImageUrl }: Props) {
  const { upload, status, url, } = useUploadFiles();
  const loading = status === UploadFileStatus.loading;

  useEffect(() => {
    if (url) setImageUrl(url)
  }, [setImageUrl, url]);

  const validated = (file: FileType) => {
    const acceptedTypes = ['image/jpeg', 'image/png', 'image/webp'];
    if (!acceptedTypes.includes(file.type)) {
      message.error({
        content: 'You can only upload JPG/PNG file!',
      });
      return false
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
      return false
    }
    return true
  }

  const beforeUpload = (file: FileType) => {
    return false
  };

  const handleChange = (info: UploadChangeParam<UploadFile<any>>) => {
    const valid = validated(info.file as any);
    if (valid) upload(info.file as any, appId);
  }

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as FileType);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const uploadButton = (
    <ImageUploadContent>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>{loading ? "Uploading..." : "Add logo"}</div>
    </ImageUploadContent>
  );

  return (
    <ImgCrop
      modalOk='Done'
      modalProps={{
        closeIcon: <IconClose />,
        okButtonProps: {
        }
      }}
    >
      <ImageUploadWrapper
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        onPreview={onPreview}
        beforeUpload={beforeUpload}
        onChange={handleChange}
      >
        {imageUrl ? <Avatar size={120} shape='square' src={url || imageUrl} /> : uploadButton}
      </ImageUploadWrapper>
    </ImgCrop>
  );
};