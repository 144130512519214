import styled from "styled-components";

export const Wrapper = styled.div`
    width: 100%;
    gap: 30px;
    .ant-segmented-item-label {
        text-transform: capitalize;
    }
`;

export const Sidebar = styled.div`
    width: 200px;
    display: flex;
    flex-direction: column;
    gap: var(--padding);
    position: sticky;
    top: 94px;

    background: var(--dark500);
    padding: var(--padding);
    border-radius: var(--padding);

    button {
        align-self: flex-start;
    }
`;

export const Content = styled.div`
    flex: 1;
`;

export const SettingsWrapper = styled.div`
    width: 100%;
    display: flex;
`;

export const SettingsContent = styled.div`
    width: 100%;
`;

export const SettingsSideContent = styled.div`
    width: 350px;
`;

export const SettingsGrid = styled.div`
    width: 100%;
    display: flex;
`;

export const ProductTypeItemWrapper = styled.div<{ $selected: boolean; $disabled: boolean }>`
    width: 120px;
    height: 150px;
    cursor: pointer;
    transition: all 0.3s ease-in;
    
    &:hover {
        scale: 1.05;
        .product-type-grahic {
            border-color: var(--blue);
        }
    }
    ${({ $selected }) => $selected ? `
    scale: 1.05;
        .product-type-grahic {
            border-color: var(--blue);
        }
    ` : ''}
    ${({ $disabled, $selected }) => $disabled && !$selected ? `
    opacity: 0.3;
    pointer-events: none;
    ` : ''}
`;

export const ProductTypeItemGraphic = styled.div`
    width: 100px;
    height: 100px;
    border-radius: var(--radiusRegular);
    background: var(--dark500);
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid transparent;
    transition: all 0.3s ease-in;
`;

export const ProductTypeItemContent = styled.div`
    padding: 8px;
`;

export const ProductTypeItemTitle = styled.div`
    font-family: bold;
`;

export const ProductTypeItemSubtitle = styled.div`
display: flex;
align-items: center;
gap: 4px;
font-size: 12px;
color: var(--secondaryColor);
`;

export const DeviceWrapper = styled.div`
    background: var(--dark500);
    height: 400px;
    border-radius: 16px;
    margin-top: 42px;
`;

export const ProductsWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 10px;
`;

export const ProductItemWrapper = styled.div`
    height: 330px;
`;

export const ProductItemImage = styled.div`
    height: 250px;
    background: var(--dark500);
    border-radius: var(--radiusRegular);
    overflow: hidden;
`;

export const ProductItemContent = styled.div`
   padding: 8px;
   overflow: hidden;
`;

export const ProductItemTitle = styled.div`
    font-family: bold;
    max-height: 34px;
    overflow: hidden;
`;

export const ProductItemSubtitle = styled.div`
`;

export const CompactList = styled.div`
li {
    padding: 0px;
}
`;

export const ProductImagePreview = styled.div`
    width: 120px;
    height: 120px;
    border-radius: 22px;
    border: 1px solid var(--gray700);
    padding: 4px;
    overflow: hidden;
    position: relative;
    transition: all 0.3s;
    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 18px;
    }
`;