import React from 'react'
import { AppButttonWrapper, HeaderActions, HeaderContent, HeaderMain, HeaderWrapper, SelectedAppWrapper } from './styled'
import { Logo } from '../brand'
import { Link } from '../button'
import { IconApp, IconClose, IconCustomers, IconInvoices, IconPayouts, IconTransactions } from '../icons'
import { Tooltip } from '@mui/material'
import { routes } from '../../utils/routes'
import useNav from '../../utils/hooks/useNav'
import { Avatar, Popover } from 'antd'
import { useSelectedApp } from '../../pages/apps/hooks'
import { generatePath } from 'react-router-dom'
import { AccountItem } from '../../pages/auth/AccountItem'
import { useAccount } from '../../pages/auth/hooks'

export function Header() {
    const { navigate } = useNav();
    const { app, clear } = useSelectedApp();
    const { avatar_url } = useAccount();
    const isAppSelected = !!app;

    return (
        <HeaderMain>
            <HeaderWrapper>
                <HeaderContent>
                    <HeaderActions>
                        {
                            isAppSelected ?
                                <AppButttonWrapper>
                                    <Tooltip title="Apps">
                                        <div>
                                            <Link className='app-btn-first' url={routes.apps.root} rounded onClick={clear}>
                                                <IconApp size={25} />
                                            </Link>
                                        </div>
                                    </Tooltip>
                                    <Tooltip title={app?.name}>
                                        <SelectedAppWrapper>
                                            <Link className='app-btn-second' url={generatePath(routes.apps.details.root, { id: app.app_id })} rounded>
                                                <Avatar size={25} shape='square' style={{borderRadius: 6}} src={app?.icon_url} />
                                            </Link>
                                            <div onClick={clear} className='selected-app-close-btn' style={{ position: "absolute", top: -5, right: -5, cursor: "pointer", width: 18, height: 18, borderRadius: 100, background: 'var(--red600)', display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                <IconClose size={15} />
                                            </div>
                                        </SelectedAppWrapper>
                                    </Tooltip>
                                </AppButttonWrapper>
                                :
                                <Tooltip title="Apps">
                                    <div>
                                        <Link url={routes.apps.root} rounded>
                                            <IconApp size={25} />
                                        </Link>
                                    </div>
                                </Tooltip>
                        }
                        <Tooltip title="Transactions">
                            <div>
                                <Link url={routes.transactions.root} rounded>
                                    <IconTransactions size={25} />
                                </Link>
                            </div>
                        </Tooltip>
                        <Tooltip title="Customers">
                            <div>
                                <Link url={routes.customers.root} rounded>
                                    <IconCustomers size={25} />
                                </Link>
                            </div>
                        </Tooltip>
                    </HeaderActions>
                    <Logo showLogoText onClick={() => navigate(routes.home)} />
                    <HeaderActions>
                        <Tooltip title="Invoices">
                            <div>
                                <Link url={routes.invoices.root} rounded>
                                    <IconInvoices size={25} />
                                </Link>
                            </div>
                        </Tooltip>
                        <Tooltip title="Payouts">
                            <div>
                                <Link url={routes.payouts.root} rounded>
                                    <IconPayouts size={25} />
                                </Link>
                            </div>
                        </Tooltip>
                        <Popover
                            content={<AccountItem />}
                            trigger="click"
                            placement='bottomRight'
                            overlayInnerStyle={{ borderRadius: 8, background: 'var(--dark400)' }}
                        >
                            <div style={{ cursor: "pointer" }}>
                                <Avatar size={40} alt="Remy Sharp" src={avatar_url} />
                            </div>
                        </Popover>
                    </HeaderActions>
                </HeaderContent>
            </HeaderWrapper>
        </HeaderMain>
    )
}
