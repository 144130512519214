import React, { useMemo } from 'react'
import { StatusCellWrapper } from '../../../wallet/transactions/components/styled';
import { OrderStatus } from '../types';
import { IconCheck, IconShirt } from '../../../../components/icons';

export function OrderStatusCell({ status }: { status: OrderStatus }) {
    const { color, icon, background, label } = useMemo(() => {
        switch (parseInt(status as any)) {
            case OrderStatus.RECEIVED:
                return {
                    color: "var(--gray600)",
                    background: "var(--gray10)",
                    label: "Received",
                }

            case OrderStatus.SHIPPED:
                return {
                    color: "var(--yellow600)",
                    background: "var(--yellow10)",
                    label: "Shipped",
                    icon: <IconShirt size={13} color="var(--green600)" />
                }
            case OrderStatus.COMPLETED:
                return {
                    color: "var(--green600)",
                    background: "var(--green10)",
                    label: "Compeleted",
                    icon: <IconCheck size={13} color="var(--green600)" />
                }
            default:
                return {}
        }
    }, [status]);

    return (
        <StatusCellWrapper style={{
            color,
            background
        }}>
            {icon}
            {label}
        </StatusCellWrapper>
    )
}
