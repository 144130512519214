import React from 'react'
import { Content } from '../../../components/content'
import { Shimmer } from '../../../components/Shimmer'
import useNav from '../../../utils/hooks/useNav'
import { DataResponseView } from '../../../components/DataResponseView';
import { useTransaction } from './hooks';
import { FlexPad, Flex } from '../../apps/styled';
import { Avatar } from 'antd';
import { ListItem } from '../../../components/listitem';
import { ListItemIcon, ListItemText } from '@mui/material';
import { IconInfo } from '../../../components/icons';
import { StatusCell } from './components';
import { CurrencyConversion } from '../components/CurrencyConversion';
import { TransactionDetailsShimmer } from './components/TransactionDetailsShimmer';

export function TransactionDetailsPage() {
  const { params } = useNav();
  const transferId = params?.id;
  const { data, error, isLoading, isRefetching, refetch } = useTransaction(transferId);
  const { amount, currency, sender, receiver, international, conversion_rate } = data;

  const isInternational = !!international;
 
  return (
    <Shimmer.SmoothTransition>
      <Content showBackButton title='Transaction details'>
        <Content.Main>
          <DataResponseView
            content={(
              <Flex style={{ width: '100%' }}>
                <FlexPad style={{ alignSelf: "flex-start" }}>
                  <Avatar style={{background: 'var(--dark600)',}} src={data?.sender?.avatar_url} size={120} />
                </FlexPad>
                <Flex style={{ flex: 1 }}>
                  <Flex style={{ width: '100%', flexDirection: 'column' }}>
                    <FlexPad style={{ flex: 1, gap: 24 }}>
                      <div style={{fontFamily: 'extreme-bold', fontSize: 60,}}><span style={{fontSize: 30}}>{currency}</span> {(amount)}</div>
                      {isInternational && (
                        <CurrencyConversion
                            amount={parseFloat(amount as unknown as string)}
                            rate={conversion_rate!}
                            from={{
                                country: sender?.country!,
                                currency: sender?.currency!,
                            }}
                            to={{
                                country: receiver?.country as any,
                                currency: receiver?.currency as any,
                            }}
                        />
                    )}
                    </FlexPad>
                    <FlexPad>
                      <ListItem>
                        <ListItemIcon>
                          <IconInfo />
                        </ListItemIcon>
                        <div>
                          <ListItemText secondary={"Status"} />
                          <ListItemText primary={<StatusCell status={"success"} />} />
                        </div>
                      </ListItem>
                    </FlexPad>
                    <FlexPad>
                      <ListItem>
                        <ListItemIcon>
                          <IconInfo />
                        </ListItemIcon>
                        <div>
                          <ListItemText secondary={"Method"} />
                          <ListItemText primary={'ChuttApp Pay'} />
                        </div>
                      </ListItem>
                    </FlexPad>
                    <FlexPad>
                      <ListItem>
                        <ListItemIcon>
                          <IconInfo />
                        </ListItemIcon>
                        <div>
                          <ListItemText secondary={"Type"} />
                          <ListItemText primary={"Credit"} />
                        </div>
                      </ListItem>
                    </FlexPad>
                  </Flex>
                </Flex>
              </Flex>
            )}
            customLoadingComponent={<TransactionDetailsShimmer />}
            isLoading={isLoading}
            data={data}
            error={error}
            isRefetching={isRefetching}
            onRetry={refetch}
          />
        </Content.Main>
      </Content>
    </Shimmer.SmoothTransition>
  )
}
