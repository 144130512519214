import React from 'react'
import { AmountCellTitle, AmountCellWrapper } from '../../../wallet/transactions/components/styled'


export function TableAmount({ price, currency }: { price: number, currency: string }) {

    return (
        <AmountCellWrapper>
            <AmountCellTitle>{currency} {price}</AmountCellTitle>
        </AmountCellWrapper>
    )
}
