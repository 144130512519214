import React from 'react';
import { type GetProp, type UploadFile, type UploadProps } from 'antd';
import ImgCrop from 'antd-img-crop';
import { IconClose } from '../icons';
import { ImageUploadWrapper } from './styled';
import { UploadedItem } from './UploadedItem';
import { create } from 'zustand';

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

interface Props {
    appId: string;
    fileList: UploadFile<any>[]
    setFileList: React.Dispatch<React.SetStateAction<UploadFile<any>[]>>
}

export function MultiUpload({ fileList, appId, setFileList }: Props) {

    const beforeUpload = (file: FileType) => {
        return false
    };

    const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };

    return (
        <ImgCrop rotationSlider modalOk='Done'
            modalProps={{
                closeIcon: <IconClose />,
                okButtonProps: {
                }
            }}>
            <ImageUploadWrapper
                listType="picture-card"
                fileList={fileList}
                itemRender={(_: any, file: any, fileList: any, actions: any) => <UploadedItem appId={appId} actions={actions} file={file} fileList={fileList} />}
                beforeUpload={beforeUpload}
                onChange={onChange}
            >
                {fileList.length < 5 && '+ Upload'}
            </ImageUploadWrapper>
        </ImgCrop>
    );
};

interface useCreateAccountFormState {
    urls: string[] | null;
    setFile: (file: string) => void;
    clear: () => void;
}

export const useMultiUploadedFiles = create<useCreateAccountFormState>((set, get) => ({
    urls: null,
    setFile: (file) => {
        const urls = get().urls;
        if (!urls) {
            set({ urls: [file] });
            return;
        }
        const exists = urls.includes(file);
        if (exists) {
            const newurls = urls.filter(a => a !== file);
            set({ urls: newurls });
            return
        }
        set({ urls: [...urls, file] });
    },
    clear: () => set({ urls: null })
}))
