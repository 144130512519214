import dayjs from "dayjs"

export const date = (d?: string | number | Date | dayjs.Dayjs) => {
    return dayjs(d).format('MMM DD, YYYY')
}

export const dateAndTime = (d?: string | number | Date | dayjs.Dayjs) => {
    return dayjs(d).format('MMM DD, YYYY HH:mm A')
}

export const time = (d?: string | number | Date | dayjs.Dayjs) => {
    return dayjs(d).format('HH:mm A')
}

export const chatDateOrTime = (d?: string | number | Date | dayjs.Dayjs) => {
    return dayjs(d).format('HH:mm A')
}

export const lastSeenDate = (d?: string | number | Date | dayjs.Dayjs) => {
    return dayjs(d).format('[Last seen at] MMM DD HH:mm')
}