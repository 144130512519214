import { FirebaseOptions, initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig: FirebaseOptions = {
    apiKey: "AIzaSyBDiN88v8pPibTeh2BXsgfmkRN86zRDRvw",
    authDomain: "chuttapp.firebaseapp.com",
    projectId: "chuttapp",
    storageBucket: "chuttapp.appspot.com",
    messagingSenderId: "1006485768710",
    appId: "1:1006485768710:web:9d825c41b9f46ea0745187",
    measurementId: "G-JFFSP14H0K",
};

// Initialize Firebase
export const setUpFirebase = () => {
    const app = initializeApp(firebaseConfig);
    getAnalytics(app);
}