import { useQuery } from "react-query";
import { InApp } from "../types";
import { _get } from "../../../utils/query";
import { appsApi } from "../../../utils/api";
import { useAccount } from "../../auth/hooks";
import { DataResponse } from "../../../utils/types";

export function useApps() {
    const {user_id} = useAccount()
    const { data, isLoading, isRefetching, error, refetch } = useQuery<DataResponse<InApp>>("get-apps", () =>
        _get(appsApi.myApps + '/'+user_id),
        {
            enabled: !!user_id,
        }
    );

    return {
        data: data?.data || [],
        isLoading,
        isRefetching,
        error: error as any,
        refetch
    }
}