export interface Origin {
  name: string;
  dial_code: string;
  code: string;
  currency: {
    symbol: string;
    code: string;
  }
}

const Countries: Origin[] = [
  {
    name: 'Ghana',
    dial_code: '+233',
    code: 'GH',
    currency: {
      symbol: "GH₵",
      code: "GHS",
    }
  },
  {
    name: 'Kenya',
    dial_code: '+254',
    code: 'KE',
    currency: {
      symbol: "Ksh",
      code: "KES",
    }
  },
  {
    name: 'Nigeria',
    dial_code: '+234',
    code: 'NG',
    currency: {
      symbol: "₦",
      code: "NGN",
    }
  },
  {
    name: 'United Kingdom',
    dial_code: '+44',
    code: 'GB',
    currency: {
      symbol: "£",
      code: "GBP",
    }
  },
  {
    name: 'United States',
    dial_code: '+1',
    code: 'US',
    currency: {
      symbol: "$",
      code: "USD",
    }
  },
];

export default Countries;
