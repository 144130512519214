import styled from "styled-components";

export const DashboardWrapper = styled.div`
    width: 100vw;
    height: 100vh;
    max-width: 1600px;
    overflow-y: hidden;
    margin: auto;
`;

export const DashboardContent = styled.div`
   width: 100%;
   height: calc(100vh - 90px); // 90px is headers height + padding top and bottom
   overflow-y: auto;
   padding: var(--padding);
   padding-top: 0;
`;

export const HomeWrapper = styled.div`
   width: var(--contentWidth);
   margin: 32px auto;
`;

export const Stack = styled.div<{$flex?: boolean}>`
   width: 100%;
   ${({$flex}) => $flex ? 'display: flex;': ''}
`;

export const StatsContent = styled.div`
   display: flex;
   align-items: center;
   justify-content: space-between;
`;