import React from 'react'
import { Shimmer } from '../../../../components/Shimmer'
import { FlexPad, Flex } from '../../../apps/styled'

export function TransactionDetailsShimmer() {
    const List = (
        <Shimmer.Flex $gap='12px'>
            <Shimmer color='var(--dark600)' width='30px' height='30px' />
            <Shimmer.Flex $gap='8px' $column>
                <Shimmer color='var(--dark600)' height='18px' width='100px' />
                <Shimmer color='var(--dark600)' height='30px' width='300px' />
            </Shimmer.Flex>
        </Shimmer.Flex>
    )
    return (
        <>
            <Flex style={{ width: '100%' }}>
                <FlexPad style={{ alignSelf: "flex-start" }}>
                    <Shimmer  color='var(--dark600)' width='120px' height='120px' radius={100} backgroundHeight='30000px' />
                </FlexPad>
                <Flex style={{ width: '100%', }}>
                    <Flex style={{ width: '100%', flexDirection: 'column' }}>
                        <Flex>
                            <FlexPad style={{ flex: 1, gap: 24 }}>
                                <Flex style={{  width: '100%', alignItems: 'center' }}>
                                    <Shimmer color='var(--dark600)' width='300px' height='40px' />
                                    <Shimmer color='var(--dark600)' width='200px' height='30px' />
                                </Flex>
                            </FlexPad>
                        </Flex>
                        <FlexPad>
                            {List}
                        </FlexPad>
                        <FlexPad>
                            {List}
                        </FlexPad>
                        <FlexPad>
                            {List}
                        </FlexPad>
                    </Flex>
                </Flex>
            </Flex>
        </>
    )
}
