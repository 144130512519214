import React from 'react'
import { ContentHeader, ContentHeaderActions, ContentHeaderTitle, ContentWrapper } from './styled'
import { getReactChildren } from '../../utils';
import { IconButton } from '../button';
import { IconAngleLeft } from '../icons';
import useNav from '../../utils/hooks/useNav';
import { Popconfirm } from 'antd';


interface ContentProps {
  title: string;
  children: any;
  showBackButton?: boolean;
  showPopupConfirm?: boolean;
  nested?: boolean;
  onGoBack?: () => void;
}

export function Content({ title, children, nested, showBackButton, showPopupConfirm, onGoBack }: ContentProps) {
  const toolbar = getReactChildren(children, "Toolbar")
  const main = getReactChildren(children, "Main");
  const { goBack } = useNav()
  return (
    <ContentWrapper style={nested ? {width: "100%"} : undefined}>
      <ContentHeader style={nested ? {height: "auto", position: "unset", top: "unset", marginBottom: 16} : undefined}>
        <ContentHeaderActions>
          {showBackButton && (
            <Popconfirm
              disabled={!showPopupConfirm}
              title="You have unsaved changes"
              description="Are you sure you want to close this page?"
              okText="Yes"
              cancelText="No"
              cancelButtonProps={{
                style: {
                  background: "transparent",
                  color: 'var(--color)',
                  borderColor: 'var(--borderColor500)'
                }
              }}
              placement='bottom'
              onConfirm={onGoBack ?? goBack}
            >
              <IconButton onClick={() => {
               if(showPopupConfirm) return;
               if(onGoBack) return onGoBack();
                goBack()
              }} isActive><IconAngleLeft /></IconButton>
            </Popconfirm>
          )}
          <ContentHeaderTitle style={nested ? {fontSize: "24px"}: undefined}>{title}</ContentHeaderTitle>
        </ContentHeaderActions>
        <ContentHeaderActions>
          {toolbar}
        </ContentHeaderActions>
      </ContentHeader>
      {main}
    </ContentWrapper>
  )
}

const Toolbar = ({ children }: { children: any }) => {
  return (
    <>
      {children}
    </>
  )
}
const Main = ({ children }: { children: any }) => {
  return (
    <>
      {children}
    </>
  )
}

Main.displayName = "Main";
Content.Main = Main

Toolbar.displayName = "Toolbar";
Content.Toolbar = Toolbar