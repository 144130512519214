export interface RequestParams {
    from?: number;
    limit?: number;
}

export enum ResponseStatus {
    SUCCESS = "success",
    FAILED = "failed"
}

export interface DataResponse<T = any> {
   data: T[];
   totalCount: number;
   currentPage: number;
}

export enum FILE_PATH {
    PROFILES = 'profiles',
  }
  
  export enum UploadFileStatus {
    idle = 'idle',
    loading = 'loading',
    uploaded = 'uploaded',
  }