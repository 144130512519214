import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getStoreItem, isEmpty, saveStoreItem } from "..";
import { themeKey } from "../constants";

export enum Theme {
  DARK = "dark",
  LIGHT = "light"
}

// Define a type for the slice state
interface AppStoreState {
  theme?: Theme;
  isLoggedIn?: boolean;
}

const initialState: AppStoreState = {
  theme: getStoreItem(themeKey) as Theme,
  isLoggedIn: !isEmpty(getStoreItem("token"))
};

export const AppStore = createSlice({
  name: "counter",
  initialState,
  reducers: {
    setLoginState: (state, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload
    },
    setTheme: (state, action: PayloadAction<Theme>) => {
      const t = action.payload === Theme.DARK ? Theme.LIGHT : Theme.DARK;
      state.theme = t;
      document.body.setAttribute("class", t);
      saveStoreItem(themeKey, t);
    },
  },
});

export const { setLoginState, setTheme } = AppStore.actions;

export default AppStore.reducer;
