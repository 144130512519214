import React from 'react'
import { StatusCellWrapper } from './styled'
import { IconCheck } from '../../../../components/icons'

export function StatusCell({ status }: { status: "success" | "failed" }) {
    return (
        <StatusCellWrapper style={{
            color: status === "success" ? "var(--green600)" : "var(--red600)",
            background: status === "success" ? "var(--green10)" : "var(--red10)",
        }}>
            <IconCheck size={13} color='var(--green600)' />
            {status}
        </StatusCellWrapper>
    )
}
