import { create } from "zustand";
import { InApp } from '../types';
import { getStoreItem, saveStoreItem } from "../../../utils";
import { SelectedAppKey } from "../../../utils/constants";

interface useSelectedAppState {
    app: InApp | null;
    setApp: (app: InApp) => void;
    clear: () => void;
}

export const useSelectedApp = create<useSelectedAppState>((set, get) => ({
    app: getStoreItem(SelectedAppKey) || null,
    setApp: (app) => {
        set({ app });
        saveStoreItem(SelectedAppKey, app)
    },
    clear: () => {
        set({ app: null });
        localStorage.removeItem(SelectedAppKey)
    }
}))
