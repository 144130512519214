import { Table, TableProps } from 'antd';
import React, { useMemo } from 'react'
import { Shimmer } from './Shimmer';
import { TotalWrapper } from './table/styled';

type Option = "normal" | "amount" | "user" | "date";
interface TableShimmerProps {
    configure?: Option[];
}

export function TableShimmer({ configure = ["normal", "amount", "user", "normal", "date"] }: TableShimmerProps) {
    const views = useMemo(() => ({
        normal: (<Shimmer height='20px' width='100%' />),
        amount: (<Shimmer height='30px' width='120px' />),
        user: (<Shimmer.Flex $gap='12px'>
           <Shimmer height='35px' width='35px' />
           <Shimmer.Flex $column $gap='5px'>
               <Shimmer height='22px' width='120px' />
               <Shimmer height='13px' width='80px' />
           </Shimmer.Flex>
       </Shimmer.Flex>),
        date: (<Shimmer height='20px' width='100%' />)
    }), [])

    const columns: TableProps<any>['columns'] = useMemo(() => {
        return configure.map((item, index) => (
            {
                title: () => <Shimmer height='20px' width='60%' />,
                dataIndex: item,
                key: item,
                render: () => views[item]
            }
        ))
    }, [configure, views])

    return (
        <>
            <TotalWrapper>
                <Shimmer width='15px' height='15px' />
                <Shimmer height='15px' width='120px' />
            </TotalWrapper>
            <Table
                className='table-shimmer'
                rowKey={({ index }) => index}
                columns={columns}
                tableLayout='fixed'
                dataSource={[1, 2, 3, 4, 5, 6, 7]}
                pagination={false}
            />
        </>
    )
}