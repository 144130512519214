import { useMutation } from "react-query";
import { useAccount } from "../../../auth/hooks";
import { httpPost } from "../../../../utils/query";
import { productsApi } from "../../../../utils/api";
import { CreateProduct, Product } from "../types";
import { ResponseStatus } from "../../../../utils/types";
import { COMMERCE_API_DOMAIN } from "../../../../utils/constants";

export function useCreateProduct() {
    const { user_id } = useAccount()
    const { data, isLoading, error, mutate } = useMutation<{ status: ResponseStatus, product: Product }, unknown, CreateProduct>((payload: CreateProduct) =>
        httpPost(COMMERCE_API_DOMAIN + productsApi.createProduct, payload),
    );

    return {
        isLoading,
        error: error as any,
        data,
        createProduct: (payload: Omit<CreateProduct, 'creator_id'>, onCompleted: () => void) => {
            mutate({
                ...payload,
                creator_id: user_id,
            }, {
                onSuccess(data, variables, context) {
                    if(data.status === ResponseStatus.SUCCESS && data.product?.product_id) {
                        onCompleted()
                    }
                },
            })
        }
    }
}
