import { useMutation } from "react-query";
import { UpdateInAppStatus } from "../types";
import { _post } from "../../../utils/query";
import { appsApi } from "../../../utils/api";
import { ResponseStatus } from "../../../utils/types";

export function useUpdateAppStatus() {
    const { data, isLoading, error, mutate } = useMutation<{status: ResponseStatus}, unknown, UpdateInAppStatus>((payload: UpdateInAppStatus) =>
        _post(appsApi.updateStatus, payload),
    );

    return {
        isLoading,
        error: error as any,
        data,
        onChangeToReviewStatus: (payload: UpdateInAppStatus, onSuccess: () => void) => {
            mutate(payload, {
                onSuccess(data, variables, context) {
                    if(data.status === ResponseStatus.SUCCESS) onSuccess();
                },
            })
        }
    }
}
