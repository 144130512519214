import React from 'react'
import { Content } from '../../../components/content'
import { Button } from '../../../components/button'
import { IconPlus } from '../../../components/icons'
import { DataResponseView } from '../../../components/DataResponseView'
import useNav from '../../../utils/hooks/useNav'
import { routes } from '../../../utils/routes'
import { useAppDetailsContext } from '../hooks/useAppDetailsContext'
import { useComerceAppContext, useProducts } from './hooks'
import { ProductItem } from './components'
import { ProductsWrapper } from './styled'
import { Table, TableProps } from '../../../components/table'
import { Product } from './types'
import { MenuDropdown } from '../../../components/menu'
import { DateCell } from './components/DateCell'
import { TableAmount } from './components/TableAmount'
import { ProductImage, ProductItemCell } from './components/ProductItemCell'
import { TableShimmer } from '../../../components/TableShimmer'
import { AvailabilityCell } from './components/AvailabilityCell'
import { Logger } from '../../../components/logger'

export function ProductsPage() {
    const { app, } = useAppDetailsContext();
    const { commerceUser, onChangeTab } = useComerceAppContext()
    const { navigate, generatePath } = useNav();
    const { data, error, totalCount, isLoading, isRefetching, tableParams, refetch } = useProducts(app?.app_id);

    const columns: TableProps<Product>['columns'] = [
        {
            title: 'Image',
            dataIndex: 'image',
            key: 'payer',
            render: (_, product) => <ProductImage image={product.imageUrl} />
        },
        {
            title: 'Name',
            dataIndex: 'payer',
            key: 'payer',
            render: (_, product) => <ProductItemCell item={product} />
        },
        {
            title: 'Price',
            dataIndex: 'amount',
            key: 'amount',
            render: (_, product) => <TableAmount price={product.price} currency={product.currency!} />
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (_, product) => <AvailabilityCell availability={product?.availability} />
        },
        {
            title: 'Date created',
            dataIndex: 'date',
            key: 'date',
            render: (_, invoice) => <DateCell date={invoice?.created_at} />
        },
        {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            fixed: "right",
            align: "right",
            render: (_, invoice) => (
                <MenuDropdown
                    items={[
                        {
                            key: 'download',
                            label: "Apply discount",
                        },
                        {
                            key: 'highlight',
                            label: "Run promo",
                        },
                        {
                            type: 'divider',
                        },
                        {
                            key: 'cancel',
                            label: "Delete",
                            danger: true,
                        },
                    ]}
                />
            )
        },
    ];

    if (!commerceUser?.app_id) return (
        <Logger
            title='Products section disabled'
            subtitle='You need to to configure your template first'
        >
            <Logger.Actions>
                <Button onClick={() => onChangeTab("root")} variant='primary'>Go to configuration</Button>
            </Logger.Actions>
        </Logger>
    )

    return (
        <Content title='Products' nested>
            <Content.Toolbar>
                <Button onClick={() => navigate(generatePath(routes.apps.details.template.createProduct, { id: app?.app_id }))} startIcon={<IconPlus />}>Add product</Button>
            </Content.Toolbar>
            <Content.Main>
                <DataResponseView
                    data={data}
                    error={error}
                    isLoading={isLoading}
                    customLoadingComponent={<TableShimmer configure={['user', 'amount', 'normal', 'date']} />}
                    content={(
                        <Table
                            rowKey={({ transfer_id }) => transfer_id}
                            columns={columns}
                            dataSource={data}
                            loading={isRefetching}
                            totalData={totalCount}
                            tableParams={tableParams}
                            leaderLabel={app?.name ? `Total products for ${app.name}` : 'Products'}
                            onRefresh={refetch}
                        />
                    )}
                />
            </Content.Main>
        </Content>
    )
}

export function ProductsPageV1() {
    const { app } = useAppDetailsContext();
    const { navigate, generatePath } = useNav();
    const { data } = useProducts("");
    // const 

    return (
        <Content title='Products' nested>
            <Content.Toolbar>
                <Button onClick={() => navigate(generatePath(routes.apps.details.template.createProduct, { id: app?.app_id }))} startIcon={<IconPlus />}>Add product</Button>
            </Content.Toolbar>
            <Content.Main>
                <DataResponseView
                    content={(
                        <ProductsWrapper>
                            {data.map((product, index) => <ProductItem item={product} key={index} />)}
                        </ProductsWrapper>
                    )}
                    data={data}
                    error={false}
                    isLoading={false}
                />
            </Content.Main>
        </Content>
    )
}
