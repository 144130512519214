import { useMutation } from "react-query";
import { UpdateInApp } from "../types";
import { _post } from "../../../utils/query";
import { appsApi } from "../../../utils/api";
import { ResponseStatus } from "../../../utils/types";

export function useUpdateApp(appId: string) {
    const { data, isLoading, error, mutate } = useMutation<{status: ResponseStatus; ap_id: string}, unknown, UpdateInApp>((payload: UpdateInApp) =>
        _post(appsApi.update + '/' + appId, payload),
    );

    return {
        isLoading,
        error: error as any,
        data,
        updateApp: (payload: UpdateInApp, onSuccess?: () => void) => {
            mutate(payload, {
                onSuccess(data, variables, context) {
                    if(data.status === ResponseStatus.SUCCESS) onSuccess?.();
                },
            })
        }
    }
}
