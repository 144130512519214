import { Invoice, InvoiceStatus } from "../../types";
import { useAccount } from "../../../auth/hooks";
import { invoicesApi } from "../../../../utils/api";
import { useTableQuery } from "../../../../utils/hooks/useTableQuery";

export function useInvoices({ appId }: { appId?: string; status?: InvoiceStatus }) {
    const { user_id } = useAccount();
    const options = useTableQuery<Invoice>({
        query: invoicesApi.getAllCreated + '/' + user_id,
        parameters: { app_id: appId, user_id, issuer: true },
        queryId: ["get-invoices" + user_id, "get-invoices" + appId,],
        enabled: !!user_id
    })
    return options;
}