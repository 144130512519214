import React from "react";
import { CurrencyKey } from "../pages/wallet/types";
import { Currencies } from "./data/Currencies";

export const appendClass = (...classNames: Array<string | undefined>) => {
  return classNames?.join(" ");
};

export const isEmpty = (data: any) => {
  return typeof data === "undefined" || data == null || data === "";
};

export const stringify = (data: any) => {
  if (isEmpty(data)) return "";
  return JSON.stringify(data);
};

export const parseJSON = (data: any) => {
  if (isEmpty(data)) return null;
  return JSON.parse(data);
};

export const getStoreItem = (key: string) => {
  const val = parseJSON(localStorage.getItem(key));
  if (isEmpty(val)) return null;
  return val;
};

export const saveStoreItem = (key: string, data: any) => {
  if (isEmpty(key) || isEmpty(data)) return false;
  localStorage.setItem(key, stringify(data));
  return true;
};

export const onEnterKeyPress = (e: any) => {
  if (e?.key === "Enter") {
    return (callback?: () => void) => {
      callback?.();
    };
  }
};

export const toUsername = (username?: string) => {
  if (!username) return null;
  return `@${username}`;
}

export const getReactChildren = (children: any, displayName: string) => {
  return React.Children.map(children, (child) => child.type.displayName === displayName ? child : null)
}

export const validURL = (url: string) => {
  var pattern = new RegExp(/^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/, 'i');
  return !!pattern.test(url);
}

export const arraysEqual = (arr1: Array<string | number>, arr2: Array<string | number>) => {
  return [...arr1].sort().toString() === [...arr2].sort().toString();
}

export function grabCurrency(currency: CurrencyKey = "GHS") {
  return {
      currency,
      format: Currencies[currency].symbol_native
  }
}