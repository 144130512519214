import { create } from "zustand";
import { User } from "../types";
import { getStoreItem, saveStoreItem } from "../../../utils";
import { userStoreKey } from "../../../utils/constants";

interface useAccountOptions {
    user: User | null;
    setUser: (user: User) => void;
    clear: () => void;
}

export const useAccountState = create<useAccountOptions>((set) => ({
    user: getStoreItem(userStoreKey) || null,
    setUser: (user) => {
        set({ user });
        saveStoreItem(userStoreKey, user)
    },
    clear: () => {
        set({ user: null });
        localStorage.removeItem(userStoreKey)
    }
}))

export const useAccount = () => {
    const {user} = useAccountState()
    return user! || {};
}