import { useEffect } from "react";
import { Header } from "../../components/header";
import { routes } from "../../utils/routes";
import { AppDetailsPage } from "../apps/AppDetailsPage";
import { AppsPage } from "../apps/AppsPage";
import { CreateAppPage } from "../apps/CreateAppPage";
import { CustomerDetailsPage, CustomersPage } from "../customers";
import { InvoicesPage } from "../wallet/invoices";
import { PayoutsPage } from "../wallet/payout";
import { TransactionDetailsPage, TransactionsPage } from "../wallet/transactions";
import { HomePage } from "./HomePage";
import { DashboardWrapper, DashboardContent } from "./styled";
import { Navigate, Route, Routes, } from 'react-router-dom'
import { useAuth } from "../auth/hooks";
import useNav from "../../utils/hooks/useNav";
import { setUpFirebase } from "../../utils/firebase";
import { Details } from "../apps/Details";
import { FInance } from "../apps/FInance";
import { Deployment } from "../apps/Deployment";
import { Settings } from "../apps/Settings";
import { CategoriesPage, CreateCategoryPage, CreateProductPage, OrdersPage, ProductDetailsPage, ProductsPage, Template, TemplateSettingsPage } from "../apps/template";

export function Dashboard() {
  const { isLoggedIn } = useAuth();
  const { navigate } = useNav()

  useEffect(() => {
    if (!isLoggedIn) {
      navigate(routes.auth.login)
    }
  }, [isLoggedIn, navigate])

  useEffect(() => {
    setUpFirebase();
  }, [])

  if (!isLoggedIn) return <></>

  return (
    <DashboardWrapper>
      <Header />
      <DashboardContent>
        <Routes>
          <Route path={routes.home} element={<HomePage />} />
          <Route path={routes.apps.root} element={<AppsPage />} />
          <Route path={routes.apps.create} element={<CreateAppPage />} />
          <Route path={routes.apps.details.root} element={<AppDetailsPage />}>
            <Route path={routes.apps.details.root} element={<Details />} />
            <Route path={routes.apps.details.template.root} element={<Template />}>
              <Route path={routes.apps.details.template.root} element={<TemplateSettingsPage />} />
              <Route path={routes.apps.details.template.productDetails} element={<ProductDetailsPage />} />
              <Route path={routes.apps.details.template.createCategory} element={<CreateCategoryPage />} />
              <Route path={routes.apps.details.template.createProduct} element={<CreateProductPage />} />
              <Route path={routes.apps.details.template.products} element={<ProductsPage />} />
              <Route path={routes.apps.details.template.categories} element={<CategoriesPage />} />
              <Route path={routes.apps.details.template.orders} element={<OrdersPage />} />
            </Route>
            <Route path={routes.apps.details.metrics} element={<FInance />} />
            <Route path={routes.apps.details.deployment} element={<Deployment />} />
            <Route path={routes.apps.details.settings} element={<Settings />} />
          </Route>
          <Route path={routes.transactions.root} element={<TransactionsPage />} />
          <Route path={routes.transactions.details} element={<TransactionDetailsPage />} />
          <Route path={routes.invoices.root} element={<InvoicesPage />} />
          {/* <Route path={routes.invoices.details} element={<InvoiceDetailsPage />} /> */}
          <Route path={routes.payouts.root} element={<PayoutsPage />} />
          <Route path={routes.customers.root} element={<CustomersPage />} />
          <Route path={routes.customers.details} element={<CustomerDetailsPage />} />
          <Route
            path="*"
            element={<Navigate to="/" replace />}
          />
        </Routes>
      </DashboardContent>
    </DashboardWrapper>
  )
}
