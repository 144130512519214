import React, { useEffect, useState } from 'react'
import { Input } from '../../../components/input'
import { InputAdornment } from '@mui/material';
import { Tooltip } from 'antd';
import { IconInfo } from '../../../components/icons';

interface Props {
    appName: string;
    editMode?: boolean;
    setAppName: (text: string) => void
}

export function AppNameInput({ appName, editMode, setAppName }: Props) {
    const [appNameError, setAppNameError] = useState('');
    const limit = 30;

    useEffect(() => {
        setAppNameError('')
        if (appName) {
            const test = appName.match(/^[A-Za-z 0-9&]*$/);
            if (!test) setAppNameError('Invalid name. Names can only contain alphabets, numbers and &')
        }
        if (appName.length > limit) setAppNameError('Should not be greater than ' + limit + ' charaters')
    }, [appName])

    return (
        <Input
            autoFocus
            value={appName}
            error={!!appNameError}
            onChange={(e) => {
                setAppName(e.target.value)
            }}
            inputProps={{ maxLength: limit, style: { fontSize: 'var(--fontLarge)', fontFamily: 'bold' } }} size='medium' label="App name" variant='standard' helperText={
                <>
                    Your app's name should be unique and be at max, {limit} characters
                    {appName.length > 0 && <div>{`${limit - appName.length} remaining`} &bull; <span style={{ color: 'var(--red600)' }}>{appNameError}</span></div>}
                </>
            }
            InputProps={{
                endAdornment: (
                    <InputAdornment position='end'>
                        <Tooltip title={editMode ? "Changing name will update your App's status to development mode. This means your app will no longer be found in the In-App Store. You will be required to go through review process again." :
                            "Changing your App's name in the future will require you to go through the deployment process again."
                        }>
                            <div style={{cursor: 'pointer'}}><IconInfo color='var(--color)' /></div>
                        </Tooltip>
                    </InputAdornment>
                )
            }}
        />
    )
}
