import React from 'react'
import { Content } from '../../../components/content'
import { InvoicesTable } from './InvoicesTable'
import { Shimmer } from '../../../components/Shimmer'

export function InvoicesPage() {
  return (
   <Shimmer.SmoothRender>
     <Content title='Invoices'>
      <Content.Main>
        <InvoicesTable />
      </Content.Main>
    </Content>
   </Shimmer.SmoothRender>
  )
}
