import styled from "styled-components";

export const Flex = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        height: 40px;
    }
`;

export const AuthWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    gap: 30px;
`;

export const QRCodeContent = styled.div`
   padding: 10px;
   border: 1px solid var(--borderColor500);
   border-radius: 8px;
`;

export const Subtitle = styled.div`
  color: var(--secondaryColor);
  font-size: var(--fontSmall);
`;

export const AccountItemWrapper = styled.div`
    width: 300px;
    background: var(--dark400);
`;