import React from 'react'
import { BankTransaction } from '../types';
import { IconDownload } from '../../../components/icons';
import { DateCell, StatusCell } from '../transactions/components';
import { MenuDropdown } from '../../../components/menu';
import { PayoutAmount } from './components';
import { To } from './components/To';
import { usePayouts } from './hooks/usePayouts';
import { DataResponseView } from '../../../components/DataResponseView';
import { Table, TableProps } from '../../../components/table';
import { TableShimmer } from '../../../components/TableShimmer';

export function PayoutsTable() {
  const { data, error, totalCount, isLoading, isRefetching, tableParams, refetch } = usePayouts();

  const columns: TableProps<BankTransaction>['columns'] = [
    {
      title: 'To',
      dataIndex: 'method',
      key: 'method',
      render: (_, tx) => <To />
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (_, tx) => <PayoutAmount amount={tx.amount} currency={tx.currency} />
    },
    {
      title: 'Balance before',
      dataIndex: 'b-before',
      key: 'b-before',
      render: (_, tx) => <PayoutAmount amount={tx.balance_before} currency={tx.currency} />
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_, tx) => <StatusCell status={"success"} />
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (_, tx) => <DateCell date={tx?.created_at} />
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      fixed: "right",
      align: "right",
      render: (_, tx) => (
        <MenuDropdown
          items={[
            {
              key: 'download',
              label: "Download receipt",
              icon: <IconDownload />
            },
          ]}
        />
      )
    },
  ];


  return (
    <DataResponseView
      data={data}
      error={error}
      isLoading={isLoading}
      customLoadingComponent={<TableShimmer configure={['user', 'amount', 'user', 'normal', 'date']} />}
      content={(
        <Table
          rowKey={({ transfer_id }) => transfer_id}
          columns={columns}
          dataSource={data}
          loading={isRefetching}
          totalData={totalCount}
          tableParams={tableParams}
          leaderLabel={'All payouts'}
          onRefresh={refetch}
        />
      )}
    />
  )
}
