import React from 'react'
import { CustomerCellContent, CustomerCellSubtitle, CustomerCellTtitle, CustomerCellWrapper } from './styled'
import { Avatar, Badge } from '@mui/material';
import ReactCountryFlag from "react-country-flag"
import { User } from '../../../auth/types';
import { toUsername } from '../../../../utils';


export function CustomerCell({ sender }: { sender: User }) {
    const { username, name, avatar_url } = sender;
    return (
        <CustomerCellWrapper>
            <Badge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={
                    <ReactCountryFlag className='emojiFlag' style={{ fontSize: 22 }} countryCode={sender?.country!} />
                }
            >
                <Avatar sx={{ width: 32, height: 32 }} src={avatar_url} />
            </Badge>
            <CustomerCellContent>
                <CustomerCellTtitle>{name}</CustomerCellTtitle>
                <CustomerCellSubtitle>{toUsername(username)}</CustomerCellSubtitle>
            </CustomerCellContent>
        </CustomerCellWrapper>
    )
}
