import React from 'react'
import { Circle, CustomerCellContent, CustomerCellSubtitle, CustomerCellTtitle, CustomerCellWrapper } from '../../transactions/components/styled'
import { IconArrowDown } from '../../../../components/icons'

export function To() {
    return (
        <CustomerCellWrapper>
            <Circle style={{background: 'var(--green10)'}} ><IconArrowDown color='var(--green600)' /></Circle>
            <CustomerCellContent>
                <CustomerCellTtitle>MTN Momo</CustomerCellTtitle>
                <CustomerCellSubtitle>Local bank</CustomerCellSubtitle>
            </CustomerCellContent>
        </CustomerCellWrapper>
    )
}
