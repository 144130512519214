import React from 'react'
import { AmountCellTitle, AmountCellWrapper } from '../../transactions/components/styled'

export function PayoutAmount({ amount, currency }: { amount: number, currency: string }) {
    return (
        <AmountCellWrapper>
            <AmountCellTitle>{currency} {amount}</AmountCellTitle>
        </AmountCellWrapper>
    )
}
