import { useMutation } from "react-query";
import { CreateInApp, CreateInAppResponse } from "../types";
import { _post } from "../../../utils/query";
import { appsApi } from "../../../utils/api";
import { useAccount } from "../../auth/hooks";

export function useCreateApp() {
    const { user_id } = useAccount()
    const { data, isLoading, error, mutate } = useMutation<CreateInAppResponse, unknown, CreateInApp>((payload: CreateInApp) =>
        _post(appsApi.create, payload),
    );

    return {
        isLoading,
        error: error as any,
        data,
        onCreateApp: (payload: Omit<CreateInApp, 'author_id'>) => {
            mutate({
                author_id: user_id,
                ...payload,
            })
        }
    }
}
