import {  MenuProps } from 'antd'
import React from 'react'
import { IconButton } from '../button'
import { DropdownWrapper } from './styled'
import { IconMenu } from '../icons'

export function MenuDropdown({ items }: { items: MenuProps['items'] }) {
  return (
    <DropdownWrapper trigger={['click']} menu={{ items }} placement="bottomRight" arrow={{ pointAtCenter: false }}>
      <IconButton><IconMenu /></IconButton>
    </DropdownWrapper>
  )
}
