import React from 'react'
import { ButtonWrapper, IconButtonWrapper } from './styled'
import { ButtonProps as BP, IconButtonProps as IBP } from '@mui/material'
import { useResolvedPath, useMatch, useNavigate } from 'react-router-dom'
import { appendClass } from '../../utils';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

export interface ButtonProps extends Omit<BP, "variant"> {
  variant?: "text" | "primary" | "secondary" | "tertiary";
  isLoading?: boolean;
  isActive?: boolean;
  
}

interface IconButtonProps extends IBP {
  rounded?: boolean;
  isActive?: boolean;
  isLoading?: boolean;
}

interface LinkProps extends IconButtonProps {
  url: string;
  isActive?: boolean;
  exact?: boolean;
}

export function Button({ variant = "tertiary", isActive, disabled, isLoading, className, ...props }: ButtonProps) {
  if (isLoading) disabled = true;
  return (
    <ButtonWrapper className={appendClass(className, isActive ? 'active-link' : undefined)} endIcon={isLoading ? <Spin style={{ color: 'var(--color)', display: 'flex' }} indicator={<LoadingOutlined spin />} size="small" /> : undefined} variant={variant === "primary" ? "contained" : variant as any} disabled={disabled} {...props} />
  )
}

export function IconButton({ rounded, isActive, className, ...props }: IconButtonProps) {
  return (
    <IconButtonWrapper className={appendClass(rounded ? 'rounded' : undefined, isActive ? 'active-link' : undefined, className)} {...props} />
  )
}

export const Link: React.FC<LinkProps> = ({ url, exact = false, isActive, onClick, ...props }) => {
  let resolved = useResolvedPath(url);
  let match = useMatch({ path: resolved.pathname, end: exact, });
  const navigate = useNavigate();

  if (!match) isActive = false;
  else isActive = true;

  const handleClick = (e: any) => {
    navigate(url);
    onClick?.(e);
  }

  if (typeof props.children === "string") {
    return <Button isActive={isActive} onClick={handleClick} {...(props as any)} />
  }

  return (
    <IconButton isActive={isActive} onClick={handleClick} {...props} />
  )
}