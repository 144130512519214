interface Props {
    size?: number;
}

const s = 120;

export const EmptyGraphic = ({size = s}: Props) => {
    return (
        <svg width={size} height={size} viewBox="0 0 120 130" fill="none">
            <path d="M105.428 48.1274C107.567 48.1274 109.301 46.308 109.301 44.0637C109.301 41.8194 107.567 40 105.428 40C103.289 40 101.555 41.8194 101.555 44.0637C101.555 46.308 103.289 48.1274 105.428 48.1274Z" fill="#EAEEF9" />
            <path d="M2.54803 70.1009C3.95526 70.1009 5.09605 68.9039 5.09605 67.4274C5.09605 65.9509 3.95526 64.7539 2.54803 64.7539C1.14079 64.7539 0 65.9509 0 67.4274C0 68.9039 1.14079 70.1009 2.54803 70.1009Z" fill="#EAEEF9" />
            <path d="M14.2608 118.167C15.668 118.167 16.8088 116.97 16.8088 115.494C16.8088 114.017 15.668 112.82 14.2608 112.82C12.8536 112.82 11.7128 114.017 11.7128 115.494C11.7128 116.97 12.8536 118.167 14.2608 118.167Z" fill="#EAEEF9" />
            <path d="M27.881 118.165L63.6369 106.42V58.2656L27.881 69.9203V118.165Z" fill="#B7BFCD" />
            <path opacity="0.4" d="M99.3928 118.165L63.6369 106.42V58.2656L99.3928 69.9203V118.165Z" fill="#6D7C9A" />
            <g opacity="0.13">
                <path d="M27.881 118.165L63.6369 106.42V58.2656L27.881 69.9203V118.165Z" fill="url(#paint0_linear_8018_15572)" />
                <path d="M99.3928 118.165L63.6369 106.42V58.2656L99.3928 69.9203V118.165Z" fill="url(#paint1_linear_8018_15572)" />
            </g>
            <path d="M63.6369 130.001L27.881 118.256V70.1016L63.6369 81.8466V130.001Z" fill="#EDF1F9" />
            <path d="M63.6369 130.001L99.3928 118.256V70.1016L63.6369 81.8466V130.001Z" fill="#DAE1ED" />
            <path opacity="0.09" d="M55.5357 121.509L27.881 118.256V70.1016L63.6369 81.8466L55.5357 121.509Z" fill="url(#paint2_linear_8018_15572)" />
            <path opacity="0.2" d="M71.7382 113.016L99.3928 118.256V70.1016L63.6369 81.8466L71.7382 113.016Z" fill="url(#paint3_linear_8018_15572)" />
            <path d="M63.6369 58.2648L46.8256 45.0742L10.4724 57.9034L27.881 69.9195L63.6369 58.2648Z" fill="#DAE1ED" />
            <path d="M63.6369 58.2648L80.4482 45.0742L116.801 57.9034L99.3928 69.9195L63.6369 58.2648Z" fill="#DAE1ED" />
            <path d="M27.881 70.1016L63.6369 81.8466L45.8016 93.7723L10.4724 81.4852L27.881 70.1016Z" fill="#EFF4FD" />
            <path d="M99.3928 70.1016L63.6369 81.8466L81.4722 93.7723L116.801 81.4852L99.3928 70.1016Z" fill="#EDF1F9" />
            <path d="M73.6131 12.0371C78.6223 26.2881 80.1154 29.4546 80.6054 43.497C80.417 46.7023 80.3001 50.1879 78.8585 52.9945C76.9333 57.288 72.2244 60.2614 67.7755 60.3096C63.1923 60.3955 58.699 57.4537 56.6766 52.9175C55.2537 50.1645 55.299 46.3988 57.2507 43.8993C59.3726 41.5021 63.1055 40.9044 65.7907 42.4023C68.7445 43.8247 70.5076 46.7824 71.1606 49.9017C71.8136 53.0209 71.5267 56.4041 70.7296 59.4802C69.0625 66.9616 67.6817 67.3235 63.5933 79.9899" stroke="#ABB5CC" strokeWidth="2" strokeMiterlimit="10" strokeDasharray="4 4" />
            <path d="M79.7095 4.49392C79.6979 6.58943 77.8377 7.87254 75.4787 7.20345C72.9948 6.75959 71.2369 6.30214 71.1238 4.43187C71.1807 2.51383 73.177 1.76314 75.5135 0.916911C78.3151 -0.250094 79.551 2.44618 79.7095 4.49392Z" fill="#DAE2EB" />
            <path d="M63.6599 10.9002C64.9186 12.2586 67.5726 12.6547 68.8658 10.5797C70.2839 8.27954 71.4977 6.60731 70.1936 5.07142C68.9348 3.713 67.7098 4.62756 64.9082 5.79457C62.6623 6.99574 62.1856 9.41203 63.6599 10.9002Z" fill="#DAE2EB" />
            <path d="M69.4682 2.23131C70.6138 1.71946 71.9861 2.09497 72.5984 3.06428C72.8592 3.37146 73.1654 3.85611 73.2561 4.21106C74.2311 6.60015 73.9246 8.96867 72.6089 9.52829C71.1683 10.3131 69.1837 8.96833 68.4241 6.70894C68.2427 5.99905 68.152 5.6441 68.016 5.11168C67.8688 3.8216 68.3226 2.74316 69.4682 2.23131C69.6383 2.18354 69.4682 2.23131 69.4682 2.23131Z" fill="#989FB0" />
            <path d="M16.8088 40.6921H14.5911V38.3672H12.6901V40.6921H10.4724V42.7513H12.6901V45.0761H14.5911V42.7513H16.8088V40.6921Z" fill="url(#paint4_linear_8018_15572)" />
            <path d="M21.6804 99.0241H19.4626V96.6992H17.5617V99.0241H15.3439V101.083H17.5617V103.408H19.4626V101.083H21.6804V99.0241Z" fill="url(#paint5_linear_8018_15572)" />
            <path d="M118.63 99.1549L115.917 97.7187L117.287 94.8742L114.961 93.6432L113.591 96.4877L110.877 95.0516L109.664 97.571L112.377 99.0072L111.007 101.852L113.333 103.083L114.703 100.238L117.417 101.674L118.63 99.1549Z" fill="url(#paint6_linear_8018_15572)" />
            <defs>
                <linearGradient id="paint0_linear_8018_15572" x1="52.3496" y1="61.7532" x2="47.0189" y2="82.9232" gradientUnits="userSpaceOnUse">
                    <stop offset="0.00289017" stopColor="#606673" stopOpacity="0" />
                    <stop offset="1" stopColor="#373C47" />
                </linearGradient>
                <linearGradient id="paint1_linear_8018_15572" x1="52.3496" y1="61.7532" x2="47.0189" y2="82.9232" gradientUnits="userSpaceOnUse">
                    <stop offset="0.00289017" stopColor="#606673" stopOpacity="0" />
                    <stop offset="1" stopColor="#373C47" />
                </linearGradient>
                <linearGradient id="paint2_linear_8018_15572" x1="35.8973" y1="103.058" x2="45.2342" y2="77.9023" gradientUnits="userSpaceOnUse">
                    <stop offset="0.00289017" stopColor="#6C80AA" stopOpacity="0" />
                    <stop offset="1" stopColor="#5D6A86" />
                </linearGradient>
                <linearGradient id="paint3_linear_8018_15572" x1="82.9339" y1="110.006" x2="83.6763" y2="77.7328" gradientUnits="userSpaceOnUse">
                    <stop offset="0.00289017" stopColor="#314F91" stopOpacity="0" />
                    <stop offset="1" stopColor="#324264" />
                </linearGradient>
                <linearGradient id="paint4_linear_8018_15572" x1="13.6411" y1="38.6002" x2="13.6411" y2="42.3659" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#B0BACC" />
                    <stop offset="1" stopColor="#969EAE" />
                </linearGradient>
                <linearGradient id="paint5_linear_8018_15572" x1="18.5126" y1="96.9323" x2="18.5126" y2="100.698" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#B0BACC" />
                    <stop offset="1" stopColor="#969EAE" />
                </linearGradient>
                <linearGradient id="paint6_linear_8018_15572" x1="115.987" y1="94.5442" x2="113.596" y2="99.0608" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#B0BACC" />
                    <stop offset="1" stopColor="#969EAE" />
                </linearGradient>
            </defs>
        </svg>

    )
}