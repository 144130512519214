import { Checkbox } from "antd";
import styled from "styled-components";

export const CheckboxWrapper = styled(Checkbox)`
    .ant-checkbox-inner {
       background: var(--gray800);
       border: 1px solid var(--gray700);
    }
    .ant-checkbox-checked {
        .ant-checkbox-inner {
            background: var(--blue);
            border: 1px solid var(--blue);
        }
    }
   &.ant-checkbox-wrapper-disabled {
        opacity: 0.4;
    }
`;