import React, { useMemo } from 'react'
import { Flex, StatsPadTitle, StatsPadWrapper } from './styled'
import { Area, AreaChart, ResponsiveContainer, Tooltip } from 'recharts'
import { Stat } from './Stat';
import { Button } from '../../../components/button';

interface ChartValue {
  name: string;
  value: number;
}
interface StatsPadProps {
  status: "up" | "down" | "average";
  id: string;
  title: string;
  chartData?: ChartValue[];
  totalValue: number;
  icon?: any;
  onClick?: () => void;
}

export function StatsPad({ status, id, title, totalValue, chartData, icon, onClick }: StatsPadProps) {
  const { chartFillColor, chartStrokeColor, tintColor } = useMemo(() => {
    switch (status) {
      case "down":
        return {
          tintColor: 'var(--red10)',
          chartFillColor: 'var(--red200)',
          chartStrokeColor: "var(--red600)"
        }
      case "up":
        return {
          tintColor: 'var(--green10)',
          chartFillColor: 'var(--green200)',
          chartStrokeColor: "var(--green600)"
        }
      default:
        return {
          tintColor: 'var(--blue10)',
          chartFillColor: 'var(--blue200)',
          chartStrokeColor: "var(--blue600)"
        }
    }
  }, [status])
  const freshData = [
    {
      name: 'Day 1',
      value: 0,
    },
    {
      name: 'Day 2',
      value: totalValue || 0,
    },
  ];
  const data = !chartData || chartData?.length < 2 ?  freshData : chartData;

  return (
    <StatsPadWrapper style={{ background: tintColor }}>
      <Button onClick={onClick} startIcon={icon} style={{ fontSize: 10, justifyContent: "flex-start", alignSelf: "flex-start", cursor: onClick ? "pointer" : 'default' }}>{title}</Button>
      <div style={{ height: 80, width: 120, margin: "auto" }}>
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            data={data}
          >
            <Tooltip />
            <defs>
              <linearGradient id={id} x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={chartFillColor} stopOpacity={0.8} />
                <stop offset="95%" stopColor={chartFillColor} stopOpacity={0} />
              </linearGradient>
            </defs>
            <Tooltip />
            <Area type="monotone" dataKey="value" stroke={chartStrokeColor} strokeWidth={2} fillOpacity={1} fill={`url(#${id})`} />
          </AreaChart>
        </ResponsiveContainer>
      </div>
      <Flex style={{ justifyContent: "space-between" }}>
        <StatsPadTitle>
          {(totalValue || 0).toLocaleString()}
        </StatsPadTitle>
        <Stat color='var(--color)' value={0} variant={status === "down" ? "error" : "success"} />
      </Flex>
    </StatsPadWrapper>
  )
}
