import React, { useMemo } from 'react'
import { IconCheck } from '../../../../components/icons'
import { ProductAvailability } from '../types'
import { StatusCellWrapper } from '../../../wallet/transactions/components/styled';

export function AvailabilityCell({ availability }: { availability: ProductAvailability }) {
    const { color, background, label } = useMemo(() => {
        switch (availability) {
            case ProductAvailability.AVAILABLE:
                return {
                    color: "var(--green600)",
                    background: "var(--green10)",
                    label: "In stock",
                    icon: <IconCheck size={13} color="var(--green600)" />
                }
            case ProductAvailability.OUT_OF_STOCK:
                return {
                    color: "var(--red600)",
                    background: "var(--red10)",
                    label: "Out of stock"
                }
            case ProductAvailability.SOLD_OUT:
                return {
                    color: "var(--yellow600)",
                    background: "var(--yellow10)",
                    label: "Sold out"
                }
            default:
                return {}
        }
    }, [availability]);

    return (
        <StatusCellWrapper style={{
            color,
            background
        }}>
            {/* {icon} */}
            {label}
        </StatusCellWrapper>
    )
}
