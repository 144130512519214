import React from 'react'
import { Content } from '../../components/content'
import { Shimmer } from '../../components/Shimmer'
import { CustomersTable } from './CustomersTable'

export function CustomersPage() {
  return (
    <Shimmer.SmoothRender>
      <Content title='Customers'>
        <Content.Main>
            <CustomersTable />
        </Content.Main>
    </Content>
    </Shimmer.SmoothRender>
  )
}
