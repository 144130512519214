import React from 'react'
import { ProductTypeItemContent, ProductTypeItemGraphic, ProductTypeItemSubtitle, ProductTypeItemTitle, ProductTypeItemWrapper } from '../styled'
import { IconInfo } from '../../../../components/icons'
import { Tooltip } from '@mui/material';

export interface ProductTypeItemProps {
  title: string;
  icon: React.ReactNode;
  learnMoreContent: React.ReactNode;
  selected: boolean;
  disabled: boolean;
  onClick?: () => void;
}

export function ProductTypeItem({ item }: { item: ProductTypeItemProps }) {
  const { icon, learnMoreContent, title, disabled, selected, onClick } = item;

  return (
    <ProductTypeItemWrapper $selected={selected} $disabled={disabled} onClick={onClick}>
      <ProductTypeItemGraphic className='product-type-grahic'>
        {icon}
      </ProductTypeItemGraphic>
      <ProductTypeItemContent>
        <ProductTypeItemTitle>{title}</ProductTypeItemTitle>
        <Tooltip title={learnMoreContent}>
          <ProductTypeItemSubtitle>Learn more <IconInfo size={14} /></ProductTypeItemSubtitle>
        </Tooltip>
      </ProductTypeItemContent>
    </ProductTypeItemWrapper>
  )
}
