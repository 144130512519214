import React from 'react'
import { InApp } from './types'
import { AppItemContent, AppItemImage, AppItemTitle, AppItemWrapper } from './styled'
import useNav from '../../utils/hooks/useNav';
import { routes } from '../../utils/routes';
import { useSelectedApp } from './hooks';
import AppStatus from './components/AppStatus';

export function AppItem({ app }: { app: InApp }) {
    const { name, icon_url, status } = app;
    const { navigate, generatePath } = useNav();
    const { setApp } = useSelectedApp();
    const goToDetailsPage = () => {
        setApp(app);
        navigate(generatePath(routes.apps.details.root, { id: app.app_id }))
    }
    return (
        <AppItemWrapper onClick={goToDetailsPage}>
            <AppItemContent>
                <AppItemImage src={icon_url} />
            </AppItemContent>
            <div style={{textAlign: 'center'}}>
                <AppItemTitle>{name}</AppItemTitle>
                <AppStatus status={status} />
            </div>
        </AppItemWrapper>
    )
}
