import React from 'react'
import { Subtitle, Title, TransactionsChartWrapper } from './styled'
import { TransactionsTable } from '../../wallet/transactions/TransactionsTable'
import { Button } from '../../../components/button'
import { IconLink } from '../../../components/icons'
import useNav from '../../../utils/hooks/useNav'
import { routes } from '../../../utils/routes'

export function TransactionsSnippet() {
  const {navigate} = useNav()
  return (
    <TransactionsChartWrapper>
      <TransactionsTable hidePagination hideTotalCounts />

      <div style={{
        padding: 'var(--paddingLarge)',
        display: "flex", 
        justifyContent: "space-between",
        paddingRight: 30
      }}>
        <div>
          <Title>Transactions</Title>
          <Subtitle>Total transactions over the selected period of a active App</Subtitle>
        </div>
        <div style={{ justifyContent: "center", display: "flex" }}>
          <Button onClick={() => navigate(routes.transactions.root)} size='small' endIcon={<IconLink />}>View all</Button>
        </div>
      </div>
    </TransactionsChartWrapper>
  )
}
