import React from 'react'
import LogoImageSVG from '../../assets/svgs/brand/logo.svg';
import { LogoImage, LogoText, LogoWrapper } from './styled';


interface Props {
    size?: number | string;
    showLogoText?: boolean;
    hideGraphic?: boolean;
    onClick?: () => void;
}

function Logo({ size = undefined, hideGraphic, showLogoText = false, onClick }: Props) {
    return (
        <>
            <LogoWrapper onClick={onClick} $actionable={!!onClick} style={{ height: size }}>
                {hideGraphic ? null : <LogoImage src={LogoImageSVG} alt='' />}
                {showLogoText && <LogoText>ChuttApp</LogoText>}
            </LogoWrapper>
        </>
    )
}

export default Logo