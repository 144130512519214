import React from 'react'
import { Product } from '../types'
import styled from 'styled-components'
import useNav from '../../../../utils/hooks/useNav';
import { routes } from '../../../../utils/routes';
import { useAppDetailsContext } from '../../hooks/useAppDetailsContext';

const ImageWrapper = styled.div`
    width: 70px;
    height: 70px;
    border-radius: var(--radiusRegular);
    background: var(--dark400);

    img {
        object-fit: cover;
        height: 100%;
        width: 100%;
    border-radius: var(--radiusRegular);
    }
`;

export function ProductImage({ image }: { image: string }) {
    return (
        <ImageWrapper>
            <img alt={image} src={image} />
        </ImageWrapper>
    )
}

export function ProductItemCell({ item }: { item: Product }) {
    const { name, caption } = item;
    const { navigate, generatePath } = useNav();
    const { app } = useAppDetailsContext();
    return (
        <div style={{cursor: "pointer", height: "100%"}} onClick={() => navigate(generatePath(routes.apps.details.template.productDetails, { id: app?.app_id, productId: item.product_id }))}>
            <div style={{ fontFamily: "bold", color: "var(--blue)" }}>{name}</div>
            <div style={{ fontSize: "bold", color: 'var(--secondaryColor)' }}>{caption}</div>
        </div>
    )
}
