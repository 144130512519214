import styled from "styled-components";

export const TotalWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 12px;
    padding-top: 0;
    b {
        font-family: bold;
    }
    span {
        color: var(--secondaryColor);
    }
`;