import React, { useState } from 'react'
import { Shimmer } from '../../components/Shimmer'
import { FinanceAmount, FinanceStatsContent, FinanceWrapper } from './styled'
import { StatsPad } from '../dashboard/components/StatsPad'
import { IconCustomers, IconEye, IconTransactions } from '../../components/icons'
import { WithdrawalForm } from '../wallet/withdraw/WithdrawalForm'
import useNav from '../../utils/hooks/useNav'
import { routes } from '../../utils/routes'
import { useAppStats } from './hooks'
import { DataResponseView } from '../../components/DataResponseView'
import { useAppDetailsContext } from './hooks/useAppDetailsContext'

export function FInance() {
  const { appId } = useAppDetailsContext();

  const [showWithdrawalForm, setShowWithdrawalForm] = useState(false);
  const { navigate } = useNav();
  const { data, error, isLoading, refetch } = useAppStats(appId);

  return (
    <>
      <DataResponseView
        content={(
          <FinanceWrapper>
            <FinanceAmount>GH₵ {(data?.overallTotalAmount || 0).toLocaleString()}</FinanceAmount>
            <div style={{ marginTop: 12 }}>
              {/* <Button onClick={() => setShowWithdrawalForm(true)} variant='primary' endIcon={<IconWithdraw />}>Withdraw</Button> */}
            </div>
            <FinanceStatsContent>
              <StatsPad onClick={() => navigate(routes.transactions.root)} title='Total transactions' totalValue={data?.totalTransactions} id='transactionsstats' status='up' icon={<IconTransactions />} />
              <StatsPad onClick={() => navigate(routes.customers.root)} title='Total customers' totalValue={data?.totalCustomers} id='customersstats' status='up' icon={<IconCustomers />} />
              <StatsPad title='Total app opens' totalValue={data?.totalOpens} id='appopensstats' status='average' icon={<IconEye />} />
            </FinanceStatsContent>
          </FinanceWrapper>
        )}
        customLoadingComponent={(
          <FinanceWrapper>
            <Shimmer height='120px' width='375px' backgroundHeight='14000px' radius="var(--radiusLarge)" />
            <div style={{ marginTop: 12 }}>
              <Shimmer height='25px' width='120px' />
            </div>
            <FinanceStatsContent>
              <Shimmer height='200px' width='200px' radius="var(--radiusLarge)" backgroundHeight='14000px' />
              <Shimmer height='200px' width='200px' radius="var(--radiusLarge)" backgroundHeight='14000px' />
              <Shimmer height='200px' width='200px' radius="var(--radiusLarge)" backgroundHeight='14000px' />
            </FinanceStatsContent>
          </FinanceWrapper>
        )}
        isLoading={isLoading}
        data={data}
        error={error}
        onRetry={refetch}
      />
      <WithdrawalForm showWithdrawalForm={showWithdrawalForm} setShowWithdrawalForm={setShowWithdrawalForm} />
    </>
  )
}
