import React from 'react'
import { Content } from '../../../components/content'
import { Button } from '../../../components/button'

export function CreateCategoryPage() {
  return (
    <Content title='Add category' nested showBackButton>
      <Content.Toolbar>
        <Button>Add</Button>
      </Content.Toolbar>
      <Content.Main>
        <></>
      </Content.Main>
    </Content>
  )
}
