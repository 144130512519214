import { useMutation } from "react-query";
import { useAccount } from "../../../auth/hooks";
import { httpPost } from "../../../../utils/query";
import { commerceApi } from "../../../../utils/api";
import { CommerceUser, CreateCommerceUser } from "../types";
import { ResponseStatus } from "../../../../utils/types";
import { COMMERCE_API_DOMAIN } from "../../../../utils/constants";

export function useCreateComerceApp() {
    const { user_id } = useAccount()
    const { data, isLoading, error, mutate } = useMutation<{ status: ResponseStatus, user: CommerceUser }, unknown, CreateCommerceUser>((payload: CreateCommerceUser) =>
        httpPost(COMMERCE_API_DOMAIN + commerceApi.create, payload),
    );

    return {
        isLoading,
        error: error as any,
        data,
        onCreateApp: (payload: Omit<CreateCommerceUser, 'user_id'>, onComplete: () => void) => {
            mutate({
                user_id: user_id,
                ...payload,
            }, {
                onSuccess(data, variables, context) {
                    if(data.status === ResponseStatus.SUCCESS) onComplete()
                },
            })
        }
    }
}
