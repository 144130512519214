import React, { useState } from 'react'
import { ButtonProps, IconButton } from '../../../../components/button';
import { IconClose, IconPlus } from '../../../../components/icons';
import { Pad as PD } from '../../styled';
import { Popover } from 'antd';
import { Input } from '../../../../components/input';

export function ColorForm({colors, setColors}: {colors: string[]; setColors: React.Dispatch<React.SetStateAction<string[]>>}) {
    const [color, setColor] = useState('');

    const add = (e: any) => {
        const isEnter = e.code === "Enter";
        if (isEnter) {
            setColors([...colors, color]);
            setColor('')
        }
    }
    const remove = (color: string) => {
        const newColors = [...colors].filter(a => a !== color);
        setColors(newColors)
    }

    return (
        <>
            {colors.map(a => (
                <Pad onClose={() => remove(a)} key={a}>{a}</Pad>
            ))}
            <Popover
                trigger="click"
                title="Enter value"
                content={(
                    <>
                        <Input placeholder='Type here..' value={color} onChange={e => setColor(e.target.value)} autoFocus onKeyUp={add} />
                    </>
                )}
            >
                <IconButton><IconPlus /></IconButton>
            </Popover>
        </>
    )
}

export function Pad(props: ButtonProps & { onClose: () => void; }) {
    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <PD {...props} />
            <IconButton onClick={props.onClose}><IconClose size={13} /></IconButton>
        </div>
    )
}
