import React from 'react'
import { IconArrowUp, IconChevronLeftDouble, IconClose } from '../../../components/icons'
import { ModalWrapper } from './styled'
import { ListItem } from '../../../components/listitem'
import { ListItemIcon, ListItemText } from '@mui/material'
import { Avatar } from 'antd'
import { Circle } from '../transactions/components/styled'
import { Button } from '../../../components/button'
import { Flex } from '../../apps/styled'

interface Props {
    showWithdrawalForm: boolean;
    setShowWithdrawalForm: (state: boolean) => void;
}

export function WithdrawalForm({ showWithdrawalForm, setShowWithdrawalForm }: Props) {
    return (
        <ModalWrapper title="Widthraw" open={showWithdrawalForm} onCancel={() => setShowWithdrawalForm(false)} closeIcon={<IconClose />} footer={<div></div>}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 20, marginTop: 15 }}>
                <ListItem bold style={{ gap: 12, background: 'var(--dark500)', borderRadius: 12 }}>
                    <ListItemIcon>
                        <Avatar size={40} icon={<IconChevronLeftDouble color='var(--blue)' />} />
                    </ListItemIcon>
                    <div>
                        <ListItemText primary="MTN Momo" style={{ fontFamily: 'bold' }} />
                        <ListItemText secondary="02....338" />
                    </div>
                </ListItem>
                <div>
                    <Circle style={{ background: 'var(--blue10)' }}>
                        <IconArrowUp color='var(--blue)' />
                    </Circle>
                </div>
                <Flex style={{ flexDirection: 'column', gap: 0, alignItems: 'center' }}>
                    <div style={{ color: 'var(--secondaryColor)', fontSize: 'var(--fontSmall)', width: 400, textAlign: 'center' }}>
                        Total amount
                    </div>
                    <div style={{ fontFamily: 'extreme-bold', fontSize: 40 }}>GH₵ {(838902).toLocaleString()}</div>
                </Flex>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 6 }}>
                    <div style={{ color: 'var(--secondaryColor)', fontSize: 'var(--fontSmall)', width: 400, textAlign: 'center' }}>
                        Withdrawals may take some time to reflect to your selected bank account.
                        This could range between now and 24 hours.
                    </div>
                    <Button variant='primary'>Withdraw</Button>
                </div>
            </div>
        </ModalWrapper>
    )
}
