import React from 'react'
import { Content } from '../../../components/content'
import { TransactionsTable } from './TransactionsTable';
import { Shimmer } from '../../../components/Shimmer';

export function TransactionsPage() {

  return (
    <Shimmer.SmoothRender>
      <Content title='Transactions'>
        <Content.Main>
          <TransactionsTable />
        </Content.Main>
      </Content>
    </Shimmer.SmoothRender>
  )
}
