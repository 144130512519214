import React, { useEffect, useState } from 'react'
import { Button } from '../../components/button'
import { ImageUpload } from '../../components/upload/ImageUpload'
import { Input } from '../../components/input'
import { Flex, FlexPad } from './styled'
import { Capabilities } from './components/Capabilities'
import { SupportedCountries } from './components/SupportedCountries'
import { message, Popconfirm } from 'antd'
import { AppNameInput } from './components/AppNameInput'
import { UrlInput } from './components/UrlInput'
import { Shimmer } from '../../components/Shimmer'
import { Capability, InAppTypeEnum } from './types'
import { useSelectedApp, useUpdateApp } from './hooks'
import { arraysEqual } from '../../utils'
import { useAppDetailsContext } from './hooks/useAppDetailsContext'
import { DevUrlInput } from './components/DevUrlInput'

export function Settings() {
  const { app, onChangeTab, refetch } = useAppDetailsContext()
  const data = app;
  const [appName, setAppName] = useState(data?.name || '');
  const [appUrl, setAppUrl] = useState(data?.url || '')
  const [imageUrl, setImageUrl] = useState<string | undefined>(data?.icon_url);
  const [caption, setCaption] = useState(data?.caption || '')
  const [description, setDescription] = useState(data?.description || '');
  const [devUrl, setDevUrl] = useState('')
  const [capabilities, setCapabilities] = useState<Capability[]>(data?.capabilities || []);
  const [supportedCountries, setSupportedCountries] = useState<string[]>(data?.supported_countries || []);
  const { app: selectedApp, setApp } = useSelectedApp();
  // eslint-disable-next-line eqeqeq
  const [isNative] = useState<boolean>(app.type == InAppTypeEnum.NATIVE);

  const { error, isLoading, updateApp } = useUpdateApp(app?.app_id);

  const urlVal = isNative ? false : !appUrl;
  const isurlEdited = isNative ? true : appUrl !== data?.url


  const disabled = !appName || urlVal || !imageUrl || !caption || !description;
  const edited = appName !== data?.name || isurlEdited || description !== data?.description || caption !== data?.caption || imageUrl !== data?.icon_url || devUrl !== data?.dev_url
    || !arraysEqual(supportedCountries, data?.supported_countries) || !arraysEqual(capabilities, data?.capabilities || [])

  const onUpdateApp = () => {
    if (disabled) return message.error('One or more items are empty. Please check to make sure all required values are addressed.');
    const payload = {
      caption,
      description,
      icon_url: imageUrl,
      name: appName,
      supported_countries: supportedCountries,
      capabilities,
      dev_url: devUrl
    }
    updateApp(payload, () => {
      refetch();
      if (selectedApp?.app_id === app.app_id) {
        setApp({
          ...selectedApp,
          ...payload
        })
      }
    })
  }

  useEffect(() => {
    if (error) message.error(error?.description || 'Something happened while updating app')
  }, [error])

  return (
    <Shimmer.SmoothRender>
      <div style={{ display: "flex", justifyContent: 'flex-end', gap: 12, marginBottom: 12 }}>
        <Popconfirm
          disabled={!edited}
          title="You have unsaved changes"
          description="Are you sure you want to close this page?"
          okText="Yes"
          cancelText="No"
          cancelButtonProps={{
            style: {
              background: "transparent",
              color: 'var(--color)',
              borderColor: 'var(--borderColor500)'
            }
          }}
          placement='bottom'
          onConfirm={() => onChangeTab("Details")}
        >
          <Button onClick={() => edited ? undefined : onChangeTab("Details")}>Cancel</Button>
        </Popconfirm>
        <Button onClick={onUpdateApp} isLoading={isLoading} disabled={disabled || !edited} variant='primary'>Update</Button>
      </div>
      <Flex style={{ width: '100%' }}>
        <Flex style={{ width: '100%', flexDirection: 'column' }}>
          <FlexPad style={{ flex: 1, gap: 24 }}>
            <ImageUpload imageUrl={imageUrl} appId={app?.app_id} setImageUrl={setImageUrl} />
            <AppNameInput appName={appName} editMode setAppName={setAppName} />
          </FlexPad>
          {
            isNative ?
              null :
              <>
                <FlexPad style={{ flex: 1 }}>
                  <UrlInput disabled appUrl={appUrl} setAppUrl={setAppUrl} />
                </FlexPad>
                <FlexPad style={{ flex: 1 }}>
                  <DevUrlInput appUrl={devUrl} setAppUrl={setDevUrl} />
                </FlexPad>
              </>
          }
          <FlexPad style={{ flex: 1 }}>
            <Input
              value={caption}
              onChange={(e) => setCaption(e.target.value)}
              inputProps={{ maxLength: 30 }}
              fullWidth size='medium' label="Caption" variant='standard' helperText={`Short and at max ${caption.length > 0 ? `${caption.length}/30` : '30'} characters description of your app`} />
          </FlexPad>
          <FlexPad style={{ flex: 1 }}>
            <Input
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              size='medium' fullWidth maxRows={30} label="Description" multiline variant='standard' helperText="Provide descriptive details of your app." />
          </FlexPad>
        </Flex>
        <Flex style={{ flexDirection: "column", width: 350 }}>
          <Capabilities capabilities={capabilities} setCapabilities={setCapabilities} />
          <SupportedCountries supportedCountries={supportedCountries} setSupportedCountries={setSupportedCountries} />
        </Flex>
      </Flex>
      {/* <div style={{ margin: '50px 0' }}>
        <Popconfirm
          title="Confirm"
          description="Are you sure you want to delete this App?"
          okText="Yes"
          cancelText="No"
          cancelButtonProps={{
            style: {
              background: "transparent",
              color: 'var(--color)',
              borderColor: 'var(--borderColor500)'
            }
          }}
          placement='topLeft'
          onConfirm={goBack}
        >
          <Button style={{ background: 'var(--red600)' }}>Delete this app</Button>
        </Popconfirm>
      </div> */}
    </Shimmer.SmoothRender>
  )
}
