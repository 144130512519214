import React from 'react'
import { AmountCellSubtitle, AmountCellTitle, AmountCellWrapper } from './styled';
import { TransferLog } from '../../types';

interface TableAmountProps {
    transfer: TransferLog;
}

export function TableAmount({ transfer }: TableAmountProps) {
    const { amount, international, international_amount, international_currency, currency } = transfer;
    const internationalDisplay = `${international_currency} ${international_amount}`;
    const normalDisplay = `${currency} ${amount}`;

    return (
        <AmountCellWrapper>
            <AmountCellTitle>{currency} {amount}</AmountCellTitle>
            {international && <AmountCellSubtitle>{international ? internationalDisplay : normalDisplay}</AmountCellSubtitle>}
        </AmountCellWrapper>
    )
}
