import { productsApi } from "../../../../utils/api";
import { COMMERCE_API_DOMAIN } from "../../../../utils/constants";
import { useTableQuery } from "../../../../utils/hooks/useTableQuery";
import { Product } from "../types"

export function useProducts(appId: string) {
  const options = useTableQuery<Product>({
    query: productsApi.getProductsByApp + '/' + appId,
    queryId: "get-products" + appId,
    enabled: !!appId,
    domain: COMMERCE_API_DOMAIN
  })
  return options;
}
