import { RequestParams, ResponseStatus } from "../../utils/types";
import { User } from "../auth/types";

export enum InAppStatus {
    DEV_MODE = "development",
    PUBLISHED = "published",
    REVIEW_PENDING = "review-pending",
    SUSPENDED = "suspended",
}

export enum InAppTypeEnum {
    EXTENSION = "extension",
    NATIVE = "native",
}

export enum NativeInAppTypeEnum {
    COMMERCE = "commerce",
}

export enum Capability {
    PAYMENT = 1,
    SCANNER = 2,
    SEND_MESSAGE = 3
}

export interface InApp {
    app_id: string;
    name: string;
    caption: string;
    description: string;
    url: string;
    dev_url?: string;
    author_id: string;
    status: InAppStatus;
    type?: InAppTypeEnum;
    native_type?: NativeInAppTypeEnum;
    icon_url: string;
    supported_countries: string[];
    created_at: string;
    capabilities?: Capability[];
    author?: User;
}


export interface CreateInApp {
    name: string;
    author_id: string;
    caption: string;
    description: string;
    url: string;
    dev_url?: string;
    icon_url: string;
    type?: InAppTypeEnum;
    native_type?: NativeInAppTypeEnum;
    supported_countries: string[];
    capabilities?: Capability[]
}

export interface UpdateInApp {
    name: string;
    caption: string;
    description: string;
    icon_url: string;
    dev_url?: string;
    supported_countries: string[];
    capabilities?: Capability[]
}

export interface UpdateInAppStatus {
    app_id: string;
    status: InAppStatus;
}

export interface GetApps extends RequestParams {
    country?: string;
}

export interface GetAuthorApps extends RequestParams {
}

export interface SearchApps extends RequestParams {
    search: string;
    country?: string;
}

export enum AppOperationTransferActions {
    /** Users to pay you */
    PAY = 'PAY',
    /** Send transfer to user */
    SEND_TRANSFER = 'SEND_TRANSFER'
}

export enum AppOperationScannerActions {
    OPEN_SCANNER = 'OPEN_SCANNER',
    CLOSE_SCANNER = 'CLOSE_SCANNER'
}

export type AppActionType = AppOperationTransferActions | AppOperationScannerActions;

export interface AppOperationPayload<T = any> {
    action: AppActionType;
    data: T
}

export type AppOperationResponseStatus = "success" | "failed";

export interface AppOperationResponse<T = any> {
    reference: string;
    action: AppActionType;
    status: AppOperationResponseStatus,
    data: T;
}

export enum FeedbackType {
    RESPONSIVE_ISSUE = "Responsive-issue",
    DESIGN_QUALITY = "design-quality",
    NOT_FUNCTIONAL = "not-functional",
    INAPPROPRIATE = "inappropriate",
    ABUSE = "abuse"
}

export interface InAppReview {
    id: string;
    app_id: string;
    resolved: boolean;
    type: FeedbackType;
    note: string;
}

export interface CreateInAppResponse { status: ResponseStatus; app_id: string }

export type AppDetailTab = "Details" | "Metrics" | "Deployment" | "Settings" | "Template";

export interface AppDetailsOutletContext {
    app: InApp;
    appId: string;
    inAppStatus: InAppStatus;
    onChangeTab: (value: AppDetailTab) => void;
    goBack: () => void;
    refetch: () => void;
}