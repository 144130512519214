import { Currencies } from "../../utils/data/Currencies";
import { RequestParams } from "../../utils/types";
import { User } from "../auth/types";

export type CurrencyKey = keyof typeof Currencies;

export enum WalletOperationEnum {
  TOP_UP = "topup",
  TRANSFER = "transfer",
  CHAT_TRANSFER = "chat-transfer",
  PAYMENT = "payment",
  WITHDRAWAL = "withdrawal",
  CHANGE_PIN = "change-pin"
}

export enum TransactionTypeEnum {
  TRANSFER = 1,
  BANK = 2,
}

export enum TransferType {
  DEBIT = 1,
  CREDIT = 2,
}

export interface Balance {
  id: string;
  amount: number;
  owner_id: string;
  updated_at: string;
}

export interface TopUpRequest {
  amount: number;
  owner_id: string;
}

export interface WithdrawRequest {
  amount: number;
  owner_id: string;
}

export enum ConfirmationStatus {
  SUCCESS = "success",
  FAILED = "failed"
}
export enum BankTypeEnum {
  THIRD_PARTY_WALLET = 1,
  BANK = 2,
  DEBIT_CARD = 3,
}
export interface Bank {
  id: string;
  bank_id: string;
  beneficiary_user_id: string;
  beneficiary_name: string;
  account_number: string;
  bank_name: string;
  bank_type: BankTypeEnum;
  country: string;
  currency: string;
  bank_code: string;
  default?: boolean;
  /** Third party reference id */
  reference_id?: string;
}

export interface GetBanks extends RequestParams {
  beneficiary_user_id: string
}

export interface TransferRequest {
  receiver_id: string;
  sender_id: string;
  amount: number;
  app_id?: string;
}

export interface CurrencyConverter {
  from: CurrencyKey;
  to: CurrencyKey;
  rate: number;
}

export interface Transfer {
  id?: string;
  transfer_id: string;
  sender_id: string;
  sender: User
  receiver: User;
  receiver_id: string;
  amount: number;
  currency: string;
  created_at: string;
  conversion_rate?: number;
  receiver_balance_before?: number;
  sender_balance_before?: number;
  international: boolean;
  app_id?: string;
  international_amount?: number;
  international_currency?: string;
}

export interface TransferLog {
  amount: number;
  status: "success" | "fail";
  created_at: string;
  conversion_rate: number;
  app_id: string;
  international?: boolean;
  international_amount?: number | null;
  international_currency?: string;
  currency: string;
  transfer_id: string;
  sender: User;
}

export interface BankTransaction {
  id: string;
  transaction_id: string;
  author_id: string;
  third_party_reference_id: string;
  transaction_type: BankTransactionType;
  amount: number;
  author?: User;
  currency: string;
  balance_before: number;
  created_at: string;
}

export interface GetTransferTransactions extends RequestParams {
  user_id: string
}

export enum BankTransactionType {
  TOP_UP = 1,
  WITHDRAWAL = 2,
}

export interface GetBankTransactions extends RequestParams {
  user_id: string;
  transaction_type?: BankTransactionType;
}

export interface CreateBankPayload {
  account_number: string;
  bank_name: string;
  beneficiary_user_id: string;
  bank_type: BankTypeEnum;
  country: string;
  currency: string;
  bank_code: string;
  beneficiary_name: string;
}

export interface UpdateDefaultBank {
  beneficiary_user_id: string;
  bank_id: string;
}

export interface UpdatePasscode {
  user_id: string;
  passcode: string;
}

export interface FixedPay {
  appId: string;
  amount: number;
  currency: CurrencyKey;
  reference: string;
}

export enum InvoiceStatus {
  PENDING = 1,
  PAID = 2,
  CANCELED = 3,
}

export interface Invoice {
  id: string;
  invoice_id: string;
  issuer_id: string;
  payer_id: string;
  title: string;
  description?: string;
  amount: number;
  currency: string;
  status: InvoiceStatus;
  created_at: string;
  updated_at: string;
  international?: boolean;
  international_amount?: number;
  conversion_rate?: number;
  payer?: User;
}

export interface CreateInvoiceRequest {
  issuer_id: string;
  payer_id: string;
  title: string;
  description?: string;
  amount: number;
  currency: string;
}

export interface UpdateOptions {
  title?: string;
  description?: string;
  status?: InvoiceStatus;
}