import Animator from "./Animator";

import LoginAnimation from '../../assets/lottie/login.json'
import ChartAnimation from '../../assets/lottie/chart2.json'
import errorA from '../../assets/lottie/error.json'


export const LoginGraphicAnimation = ()=> <Animator style={{width: 450}} loop={false} file={LoginAnimation} />

export const ChartGraphicAnimation = ()=> <Animator loop={true} file={ChartAnimation} />

export const ErrorAnimation = ()=> <Animator style={{width: 120}} loop={false} file={errorA} />
