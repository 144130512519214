/* eslint-disable react-hooks/exhaustive-deps */
import { BrowserRouter as Router, } from 'react-router-dom'
import { Dashboard } from './pages/dashboard';
import { ConfigProvider } from 'antd';
import { Auth } from './pages/auth';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient()

function App() {
  return (
    <QueryClientProvider client={queryClient}>
    <ConfigProvider
      theme={{
        token: {
          // Seed Token
          colorPrimary: 'rgb(0,122,255)',
          borderRadius: 2,
          colorText: 'var(--color)',
          colorFillSecondary: 'var(--dark300)',
          colorPrimaryBgHover: 'var(--dark300)',

          colorBgContainerDisabled: 'red',
          colorTextDisabled: 'var(--gray700)',

          colorErrorBg: 'green',
          colorErrorBgHover: "blue",
          colorErrorHover: "blue",
          colorErrorTextHover: "yellow",

          colorTextDescription: "var(--secondaryColor)",
          colorSplit: "var(--borderColor500)",
          colorTextLightSolid: "white",

          borderRadiusOuter: 10,

          // Alias Token
          colorBgContainer: 'var(--dark500)',
          colorBgBase: '#30353a',
          colorBorderSecondary: 'var(--borderColor500)',
        },
      }}
    >
      <Router>
        <Auth />
        <Dashboard />
      </Router>
    </ConfigProvider>
    </QueryClientProvider>
  );
}

export default App;
