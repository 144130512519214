import { statisticsApi } from "../../../utils/api";
import { useTableQuery } from "../../../utils/hooks/useTableQuery";
import { Customer } from "../types";

export function useCustomers({ appId }: { appId?: string; }) {
    const options = useTableQuery<Customer>({
        query: statisticsApi.getAppCustomers,
        parameters: { app_id: appId },
        queryId: "get-customers" + appId,
    })
    return options;
}