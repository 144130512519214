import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useState } from "react";

export enum UploadFileStatus {
    idle = 'idle',
    loading = 'loading',
    uploaded = 'uploaded',
    error = "error"
}

export function useUploadFiles() {
    const [downloadUrl, setDownloadUrl] = useState<string | null>(null);
    const [progress, setProgress] = useState(0);
    const [status, setStatus] = useState<UploadFileStatus>(UploadFileStatus.idle);

    const upload = (file: File, appId: string, path: "apps" | "products" = "apps") => {
        const storage = getStorage();
        const storageRef = ref(storage, path +'/' + appId + file.name);

        console.log(storageRef)

        const uploadTask = uploadBytesResumable(storageRef, file);
        setStatus(UploadFileStatus.idle)

        // Register three observers:
        // 1. 'state_changed' observer, called any time the state changes
        // 2. Error observer, called on failure
        // 3. Completion observer, called on successful completion
        uploadTask.on('state_changed',
            (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setProgress(progress);
                console.log('Upload is ' + progress + '% done');
                setStatus(UploadFileStatus.loading)
                switch (snapshot.state) {
                    case 'paused':
                        console.log('Upload is paused');
                        break;
                    case 'running':
                        console.log('Upload is running');
                        break;
                }
            },
            (error) => {
                // Handle unsuccessful uploads
                setStatus(UploadFileStatus.error)
            },
            () => {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    setDownloadUrl(downloadURL)
                    setStatus(UploadFileStatus.uploaded)
                });
            }
        );
    }

    const clear = () => {
        setDownloadUrl(null);
    };

    return {
        url: downloadUrl,
        isUploading: status === UploadFileStatus.loading,
        status,
        progress,
        upload,
        clear,
    }
}
