import { useQuery } from 'react-query';
import { CommerceUser, TemplateContext } from '../types';
import { httpGet } from '../../../../utils/query';
import { commerceApi } from '../../../../utils/api';
import { COMMERCE_API_DOMAIN } from '../../../../utils/constants';
import { useOutletContext } from 'react-router-dom'

export function useCommerceApp(appId: string) {
    const { data, isLoading, isRefetching, error, refetch } = useQuery<CommerceUser>("get-commerce-user"+appId, () =>
        httpGet(COMMERCE_API_DOMAIN + commerceApi.get + '/'+appId),
        {
            enabled: !!appId,
        }
    );

    return {
        data: data || {} as CommerceUser,
        isLoading,
        isRefetching,
        error: error as any,
        refetch
    }
}

export function useComerceAppContext() {
    return useOutletContext<TemplateContext>()
  }
  