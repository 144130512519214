import React from 'react'
import styled from 'styled-components'
import { IconConvert } from '../../../components/icons';
import { grabCurrency } from '../../../utils';
import { CurrencyKey } from '../types';
import { Flag } from '../../../components/Flag';

interface CurrencyConversionProps {
    amount: number;
    rate: number;
    from: {
        country: string;
        currency: CurrencyKey;
    }
    to: {
        country: string;
        currency: CurrencyKey;
    }
}

export function CurrencyConversion({ rate = 1, amount, from, to }: CurrencyConversionProps) {
    const fromCurrency = grabCurrency(from?.currency as any)
    const toCurrency = grabCurrency(to?.currency as any)
    return (
        <Wrapper>
            <div style={{ justifyContent: "center"}}>
                <Subtext>To be received</Subtext>
                <div style={{ display: 'flex', alignItems: "center", justifyContent: "flex-end",fontFamily: 'bold' }}>
                    <div>{toCurrency.format}</div>
                    <div>{((amount || 0) * rate).toFixed(2)}</div>
                </div>
            </div>
            <Content>
                <FlagItem>
                    <FlagWrapper>
                        {from?.country && <Flag code={from.country} size={24} />}
                    </FlagWrapper>
                    <div style={{gap: 2}}>
                        <Subtext>{fromCurrency?.format}</Subtext>
                        <div>{1}</div>
                    </div>
                </FlagItem>
                <div>
                    <IconConvert size={13} />
                </div>
                <FlagItem>
                    <FlagWrapper>
                    {to?.country && <Flag code={to.country} size={24} />}
                    </FlagWrapper>
                    <div style={{gap: 2}}>
                        <Subtext>{toCurrency?.format}</Subtext>
                        <div>{rate}</div>
                    </div>
                </FlagItem>
            </Content>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
`;

const Content = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-radius: 12px;
    background: var(--dark600);
    padding: 8px;
    gap: 24px;
`;

const FlagItem = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const FlagWrapper = styled.div`
    width: 24px;
    height: 24px;
    align-items: center;
    justify-content: center;
    display: flex;
`;

const Subtext = styled.div`
    color: var(--secondaryColor);
    font-size: var(--fontSmall);
`;