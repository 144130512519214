import { useQuery } from 'react-query';
import { InApp } from '../types';
import { _get } from '../../../utils/query';
import { appsApi } from '../../../utils/api';

export function useApp(appId: string) {
    const { data, isLoading, isRefetching, error, refetch } = useQuery<InApp>("get-app"+appId, () =>
        _get(appsApi.details + '/'+appId),
        {
            enabled: !!appId,
        }
    );

    return {
        data: data || {} as InApp,
        isLoading,
        isRefetching,
        error: error as any,
        refetch
    }
}