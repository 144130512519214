import React from 'react'
import ReactCountryFlag, { ReactCountryFlagProps } from 'react-country-flag'

type Props = Omit<ReactCountryFlagProps, "countryCode"> & {
    code: string;
    size?: number;
}

export function Flag({code, size, ...props}: Props) {
  return (
    <ReactCountryFlag className='emojiFlag' style={{ fontSize: size || 22 }} {...props} countryCode={code} />
  )
}
