import React, { useState } from 'react'
import { Input } from '../../../components/input'

interface Props {
    appUrl: string;
    disabled?: boolean;
    setAppUrl: (text: string) => void;
}

export function DevUrlInput({ appUrl, disabled, setAppUrl }: Props) {
    const [error,] = useState('')

    // useEffect(() => {
    //     setError('')
    //     if (appUrl) {
    //         const test = validURL(appUrl)
    //         if (!test) setError('This must be a valid URL format. Eg: "example.com". Do not include any special characters, or HTPP(s) prefix.')
    //     }
    // }, [appUrl])

    return (
        <Input
            disabled={disabled}
            value={appUrl}
            onChange={(e) => setAppUrl(e.target.value)}
            error={!!error}
            fullWidth size='medium' label="Testing URL (optional)" variant='standard' helperText="Use this URL for testing your changes and versions. You can change this later." />
    )
}
