import { CheckboxProps } from 'antd'
import { CheckboxWrapper } from './styled'

export function Checkbox(props: CheckboxProps) {
  return (
    <CheckboxWrapper
{...props}
    />
  )
}
