import React, { useMemo } from 'react'
import { InvoiceStatus } from '../../types'
import { StatusCellWrapper } from '../../transactions/components/styled'
import { IconPaid, IconPaymentCancelled, IconPaymentPending } from '../../../../components/icons';

export function StatusCell({ status }: { status: InvoiceStatus }) {
    const { bg, color, label, icon } = useMemo(() => {
        switch (parseInt(status as any)) {
            case InvoiceStatus.CANCELED:
                return {
                    color: 'var(--red600)',
                    bg: 'var(--red10)',
                    icon: <IconPaymentCancelled color={'var(--red600)'} />,
                    label: "Cancelled"
                }
            case InvoiceStatus.PAID:
                return {
                    color: 'var(--green600)',
                    bg: 'var(--green10)',
                    icon: <IconPaid color={'var(--green600)'} />,
                    label: "Paid"
                }
            case InvoiceStatus.PENDING:
                return {
                    color: 'var(--yellow600)',
                    bg: 'var(--yellow10)',
                    icon: <IconPaymentPending color={'var(--yellow600)'} />,
                    label: "Pending"
                }
            default:
                return {}
        }
    }, [status]);

    return (
        <StatusCellWrapper style={{
            color,
            background: bg,
        }}>
            {icon}
            {label}
        </StatusCellWrapper>
    )
}
