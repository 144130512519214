import { BankTransaction, BankTransactionType } from "../../types";
import { useAccount } from "../../../auth/hooks";
import { payoutsApi } from "../../../../utils/api";
import { useTableQuery } from "../../../../utils/hooks/useTableQuery";

export function usePayouts() {
    const { user_id } = useAccount()
    const options = useTableQuery<BankTransaction>({
        query: payoutsApi.getAll + '/' + user_id,
        parameters: { transaction_type: BankTransactionType.WITHDRAWAL },
        queryId: "get-transactions" + user_id,
        enabled: !!user_id
    })
    return options;
}