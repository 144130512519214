import { useQuery } from "react-query";
import { httpGet } from "../query";
import { DataResponse, RequestParams } from "../types";
import { useMemo, useState } from "react";
import { API_DOMAIN, LIMIT } from "../constants";

interface Pagination {
    current: number;
    total: number;
}

export interface TableParams {
    pagination?: Pagination;
    onTableChange: (pagination: Pagination) => void;
}

export interface TableQuery<T> {
    data: T[],
    isLoading: boolean;
    isRefetching: boolean;
    error: any;
    tableParams: TableParams;
    totalCount: number;
    refetch: () => void;
}

type Parameter = Record<string, any> & RequestParams;

export interface useTableQueryOptions {
    query: string;
    queryId: string | string[];
    parameters?: Parameter;
    enabled?: boolean;
    domain?: string;
}

export function useTableQuery<T = any>({ query, domain = API_DOMAIN, enabled, queryId, parameters }: useTableQueryOptions): TableQuery<T> {
    const [localParameters, setLocalParameters] = useState<Parameter>({
        limit: LIMIT
    });
    const [currentPage, setCurrentPage] = useState(1);
    const parsedParameters = useMemo(() => ({ ...(parameters || {}), ...(localParameters || {}) }), [localParameters, parameters]);

    const parsedId = useMemo(() => {
        const dynamicId = parsedParameters ? JSON.stringify(parsedParameters) : '';
        if (Array.isArray(queryId)) return queryId.map(item => item.concat(dynamicId));
        return queryId + dynamicId
    }, [parsedParameters, queryId])

    const { data: responseData, isLoading, isRefetching, error, refetch } = useQuery<DataResponse<T>>(parsedId, () =>
        httpGet(domain + query, parsedParameters),
        {
            enabled
        }
    );

    const { data, totalCount } = responseData || {};

    return {
        data: data || [],
        isLoading,
        isRefetching,
        error: error as any,
        totalCount: totalCount || 0,
        tableParams: {
            pagination: {
                current: currentPage || 1,
                total: totalCount || 0
            },
            onTableChange: (pagination) => {
                setLocalParameters({
                    from: pagination.current > 1 ? (pagination.current - 1) * LIMIT : undefined,
                    limit: LIMIT,

                });
                setCurrentPage(pagination.current);
            }
        },
        refetch,
    }
}
