import { useQuery } from "react-query";
import { Transfer } from "../../types";
import { useAccount } from "../../../auth/hooks";
import { _get } from "../../../../utils/query";
import { transactionsApi } from "../../../../utils/api";

export function useTransaction(transferId?: string) {
    const { user_id } = useAccount()
    const { data, isLoading, isRefetching, error, refetch } = useQuery<Transfer>("get-transaction" + transferId, () =>
        _get(transactionsApi.getTransaction + '/' + transferId),
        {
            enabled: !!user_id,
        }
    );

    return {
        data: data || {} as Transfer,
        isLoading,
        isRefetching,
        error: error as any,
        refetch
    }
}