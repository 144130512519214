import { InputAdornment, TextFieldProps } from '@mui/material'
import React from 'react'
import { InputWrapper } from './styled'
import { IconSearch } from '../icons'

export function Input(props: TextFieldProps) {
    return (
        <InputWrapper
            size='small'
            {...props}
        />
    )
}


export function SearchInput(props: TextFieldProps) {
    return (
        <Input {...props} placeholder='Search...'
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconSearch />
              </InputAdornment>
            ),
          }}
        />
    )
}
