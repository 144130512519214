import React from 'react'
import { AccountItemWrapper } from './styled'
import { ListItem } from '../../components/listitem'
import { ListItemAvatar, ListItemIcon, ListItemText } from '@mui/material'
import { Avatar, Divider, Popconfirm } from 'antd'
import { toUsername } from '../../utils'
import { IconLogout } from '../../components/icons'
import { useAccount, useAuth } from './hooks'

export function AccountItem() {
    const { logout } = useAuth();
    const { username, name, avatar_url } = useAccount();

    return (
        <AccountItemWrapper>
            <ListItem bold style={{padding: 0}}>
                <ListItemAvatar>
                    <Avatar size={40} alt="Remy Sharp" src={avatar_url} />
                </ListItemAvatar>
                <div>
                    <ListItemText primary={name} style={{ fontFamily: 'bold !important' }} />
                    <ListItemText secondary={toUsername(username)} />
                </div>
            </ListItem>
            <Divider style={{ margin: "12px 0" }} />

            <Popconfirm
                title="Confirm"
                description="Are you sure you want to sign out?"
                okText="Yes"
                cancelText="No"
                cancelButtonProps={{
                    style: {
                        background: "transparent",
                        color: 'var(--color)',
                        borderColor: 'var(--borderColor500)'
                    }
                }}
                placement='bottom'
                onConfirm={logout}
            >
                <ListItem style={{ height: 32, borderRadius: 4, padding: 0, paddingLeft: 8 }} onClick={() => null}>
                    <ListItemIcon>
                        <IconLogout />
                    </ListItemIcon>
                    <ListItemText primary="Sign out" />
                </ListItem>
            </Popconfirm>
        </AccountItemWrapper>
    )
}
