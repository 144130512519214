import styled from "styled-components";
import { Button } from "../../components/button";

export const AppsContent = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, 150px);
    gap: 24px;
`;

export const AppItemWrapper = styled.div`
cursor: pointer;
transition: all 0.3s ease-in;
&:hover {
   transform: scale(1.03, 1.03);
}
`;

export const AppItemContent = styled.div`
    width: 150px;
    height: 150px;
    border-radius: var(--radiusLarge);
    background: var(--dark500);
    overflow: hidden;
`;

export const AppItemImage = styled.img`
height: 100%;
width: 100%;
    object-fit: cover;
`;

export const AppItemTitle = styled.div`
    font-family: bold;
    padding: var(--padding);
    padding-bottom: 5px;
    text-align: center;
`;

export const Flex = styled.div`
    display: flex;
    gap: var(--padding);

    img {
        height: 140px;
        border-radius: 12px;
    }
`;

export const FlexPad = styled.div`
    display: flex;
    gap: var(--padding);
    background: var(--dark500);
    padding: var(--padding);
    border-radius: var(--padding);
`;

export const Title = styled.div`
    font-family: bold;
    padding: var(--padding);
`;

export const Field = styled.div`
    font-family: bold;
`;

export const AppTitle = styled.div`
    font-size: var(--fontLarge);
    font-family: bold;
`;

export const AppSubtitle = styled.div`
    font-size: var(--fontRegular);
    color: var(--secondaryColor);
`;

export const AppReviewsContent = styled.div`
    background: var(--dark500);
    width: 600px;
    border-radius: 8px;
    margin: auto;
    overflow: hidden;
    margin-top: 30px;
`;

export const ReviewItemWrapper = styled.div`
    border-bottom: 1px solid var(--borderColor500);
    &:last-child {
        border-bottom: 0;
    }
`;

export const FinanceWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const FinanceAmount = styled.div`
    font-family: extreme-bold;
    font-size: 100px;
`;

export const FinanceStatsContent = styled.div`
    display: flex;
    gap: 15px;
    margin-top: 30px;
`;

export const Pad = styled(Button)`
    padding: 1px 10px;
    font-family: bold;
    font-size: 10px !important;
`;