import { ordersApi } from "../../../../utils/api";
import { COMMERCE_API_DOMAIN } from "../../../../utils/constants";
import { useTableQuery } from "../../../../utils/hooks/useTableQuery";
import { Order } from "../types";

export function useOrders(appId: string) {
  const options = useTableQuery<Order>({
    query: ordersApi.getAll + '/' + appId,
    parameters: { app_id: appId },
    queryId: "get-orders" + appId,
    enabled: !!appId,
    domain: COMMERCE_API_DOMAIN
  })
  return options;
}
