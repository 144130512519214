import React from 'react'
import { Content } from '../../components/content'
import { Button } from '../../components/button'
import { IconPlus } from '../../components/icons'
import { AppItem } from './AppItem'
import { AppsContent } from './styled'
import useNav from '../../utils/hooks/useNav'
import { routes } from '../../utils/routes'
import { useApps } from './hooks'
import { Shimmer } from '../../components/Shimmer'
import { DataResponseView } from '../../components/DataResponseView'
import { AppsPageShimmer } from './components/AppsPageShimmer'

export function AppsPage() {
    const { data, error, isLoading } = useApps();
    const { navigate } = useNav()
    const onCreateApp = () => {
        navigate(routes.apps.create)
    }

    return (
        <Shimmer.SmoothRender>
            <Content title='Apps'>
                <Content.Toolbar>
                    <Button onClick={onCreateApp} startIcon={<IconPlus />}>Create app</Button>
                </Content.Toolbar>
                <Content.Main>
                    <DataResponseView
                        content={(
                            <AppsContent>
                                {data.map((app, key) => <AppItem key={key} app={app} />)}
                            </AppsContent>
                        )}
                        data={data}
                        error={error}
                        isLoading={isLoading}
                        customLoadingComponent={<AppsPageShimmer />}
                        emptyLoggerOptions={{
                            title: 'No apps found here',
                            subtitle: 'Click the + Create app button to start'
                        }}
                    />
                </Content.Main>
            </Content>
        </Shimmer.SmoothRender>
    )
}
