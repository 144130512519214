import React from 'react'
import { TableProps, Tooltip } from 'antd';
import { Flex } from './styled';
import { CustomerCell } from './components/CustomerCell';
import { useSelectedApp } from '../apps/hooks';
import { DataResponseView } from '../../components/DataResponseView';
import { TableShimmer } from '../../components/TableShimmer';
import { Table } from '../../components/table';
import { useCustomers } from './hooks';
import { Customer } from './types';
import { IconButton } from '../../components/button';
import { IconChat } from '../../components/icons';

export function CustomersTable() {
    const { app } = useSelectedApp();
    const { data, error, totalCount, isLoading, isRefetching, tableParams, refetch } = useCustomers({ appId: app?.app_id });

    const columns: TableProps<Customer>['columns'] = [
        {
            title: 'Customer',
            dataIndex: 'customer',
            key: 'customer',
            render: (_, customer) => <CustomerCell user={customer?.user!} />
        },
        {
            title: 'Total sales',
            dataIndex: 'total',
            key: 'total',
            render: (_, customer) => customer.total_sales
        },
        {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            fixed: "right",
            align: "right",
            width: 'min-content',
            render: (_, customer) => (
                <Flex style={{ justifyContent: "flex-end" }}>
                    <Tooltip title="Send a message to this user" placement='top'>
                        <div>
                        <IconButton isActive>
                            <IconChat size={25} />
                        </IconButton>
                        </div>
                    </Tooltip>
                </Flex>
            )
        },
    ];

    return (
        <DataResponseView
            data={data}
            error={error}
            isLoading={isLoading}
            isRefetching={isRefetching}
            customLoadingComponent={<TableShimmer configure={['user', 'amount', 'normal']} />}
            onRetry={refetch}
            content={(
                <Table
                    rowKey={({ transfer_id }) => transfer_id}
                    columns={columns}
                    dataSource={data}
                    tableLayout='fixed'
                    loading={isRefetching}
                    totalData={totalCount}
                    tableParams={tableParams}
                    leaderLabel={app?.name ? `Total customers for ${app.name}` : 'All customers across all apps'}
                    onRefresh={refetch}
                />
            )}
        />
    )
}
