import { useQuery } from 'react-query';
import { Product,  } from '../types';
import { httpGet } from '../../../../utils/query';
import { productsApi } from '../../../../utils/api';
import { COMMERCE_API_DOMAIN } from '../../../../utils/constants';

export function useProduct(appId: string, productId: string) {
    const { data, isLoading, isRefetching, error, refetch } = useQuery<Product>("get-product"+productId+appId, () =>
        httpGet(COMMERCE_API_DOMAIN + productsApi.getProduct + '/'+appId+'/'+productId),
        {
            enabled: !!appId,
        }
    );

    return {
        data: data || {} as Product,
        isLoading,
        isRefetching,
        error: error as any,
        refetch
    }
}
