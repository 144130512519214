import React, { useMemo, useState } from 'react'
import ReactCountryFlag from "react-country-flag"
import { FlexPad, Title } from '../styled'
import { Input } from '../../../components/input'
import Countries from '../../../utils/data/Countries'
import { ListItem } from '../../../components/listitem';
import { ListItemIcon, ListItemText } from '@mui/material';
import { Checkbox } from '../../../components/checkbox'


interface SupportedCountriesProps {
    editable?: boolean;
    supportedCountries?: string[];
    setSupportedCountries?: (supportedCountries: string[]) => void;
}

export function SupportedCountries({ editable = true, supportedCountries, setSupportedCountries }: SupportedCountriesProps) {
    const [searchTerm, setSearchTerm] = useState('');
    const data = useMemo(() => {
        if (searchTerm) {
            const arr = [...Countries];
            return arr.filter((origin) => {
                return (
                    origin.name.toLowerCase().includes(searchTerm.toLowerCase())
                    || origin.dial_code.toLowerCase().includes(searchTerm.toLowerCase())
                )
            })
        }
        return Countries;
    }, [searchTerm]);

    const onChange = (value: string, shouldRemove: boolean) => {
        const values = new Set(supportedCountries);
        if (values.has(value) && shouldRemove) values.delete(value);
        else values.add(value);
        setSupportedCountries?.(Array.from(values))
    }

    return (
        <FlexPad style={{ flexDirection: "column", padding: 0, gap: 0 }}>
            <Title>Supported countries</Title>
           {
            editable &&  <div style={{ padding: '0 var(--padding)', paddingBottom: 'var(--padding)' }}>
            <Input
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder='Search'
                fullWidth
                variant='standard'
            />
        </div>
           }
            {
                data.map((country, i) => (
                    <ListItem key={i}>
                        <ListItemIcon>
                            <ReactCountryFlag
                                countryCode={country.code}
                                style={{fontSize: 24, color: 'white'}}
                            />
                        </ListItemIcon>
                        <ListItemText primary={country.name} />
                        <Checkbox onChange={(state) => onChange(country.code, state.target.checked === false)} checked={supportedCountries?.includes(country.code)} disabled={!editable} />
                    </ListItem>
                ))
            }
        </FlexPad>
    )
}
