import { Table as TB, TableProps as TBProps } from 'antd'
import React from 'react'
import { Button, IconButton } from '../button'
import { IconChevronLeft, IconChevronLeftDouble, IconChevronRight, IconChevronRightDouble } from '../icons'
import { TotalWrapper } from './styled'
import { Logger } from '../logger'
import { EmptyGraphic } from '../icons/graphics'
import { TableParams } from '../../utils/hooks/useTableQuery'
import { LIMIT } from '../../utils/constants'
import Loading from '../Loading'

export interface TableProps<RecordType = any> extends TBProps<RecordType> {
  leaderLabel?: string;
  totalData?: number;
  hidePagination?: boolean;
  hideTotalCounts?: boolean;
  tableParams?: TableParams;
  onRefresh: () => void;
}

export function Table({ leaderLabel, loading, totalData, hidePagination, hideTotalCounts, tableParams, onRefresh, ...props }: TableProps) {
  const handleTableChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    tableParams?.onTableChange?.({ current: pagination.current!, total: pagination.total! })
  };
  return (
    <>
      {
        hideTotalCounts ? null :
          <TotalWrapper>
            <b>{(totalData || 0).toLocaleString()}</b>
            <span>{leaderLabel}</span>
            {loading ? <Loading /> : null}
          </TotalWrapper>
      }
      <TB
        {...props}
        locale={{
          emptyText: (
            <Logger
              title='No data to display'
              subtitle="Refresh this table and let's see what happens!"
              graphic={<EmptyGraphic />}
            >
              <Logger.Actions>
                <Button style={{ color: 'white !important' }} variant='primary' isLoading={loading as boolean} onClick={onRefresh}>Refresh</Button>
              </Logger.Actions>
            </Logger>
          )
        }}
        onChange={handleTableChange}
        pagination={hidePagination ? false : {
          pageSize: LIMIT,
          ...tableParams?.pagination,
          itemRender(_, type, element) {
            if (type === "next") return <IconButton><IconChevronRight /></IconButton>
            if (type === "prev") return <IconButton><IconChevronLeft /></IconButton>
            if (type === "jump-next") return <IconButton><IconChevronRightDouble /></IconButton>
            if (type === "jump-prev") return <IconButton><IconChevronLeftDouble /></IconButton>
            return element
          }
        }}
      />
    </>
  )
}
