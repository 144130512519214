import React from 'react'
import { Content } from '../../../components/content'
import { PayoutsTable } from './PayoutsTable'
import { Shimmer } from '../../../components/Shimmer'

export function PayoutsPage() {
    return (
       <Shimmer.SmoothRender>
         <Content title='Payouts'>
            <Content.Main>
                <PayoutsTable />
            </Content.Main>
        </Content>
       </Shimmer.SmoothRender>
    )
}
