import React, { useMemo } from 'react'
import { AppTitle, Flex, FlexPad } from './styled'
import { Avatar } from 'antd'
import { SupportedCountries } from './components/SupportedCountries'
import { ListItem } from '../../components/listitem'
import { ListItemIcon, ListItemText } from '@mui/material'
import { IconAppURL, IconCaption, IconDescription } from '../../components/icons'
import { Capabilities } from './components/Capabilities'
import { Shimmer } from '../../components/Shimmer'
import AppStatus from './components/AppStatus'
import { useAppDetailsContext } from './hooks/useAppDetailsContext'
import { InAppTypeEnum, NativeInAppTypeEnum } from './types'

export function Details() {
  const { app } = useAppDetailsContext();
  const { icon_url, name, url, caption, status, type, dev_url, native_type, description, capabilities, supported_countries } = app;

  const nativeType = useMemo(() => {
    switch (native_type) {
      case NativeInAppTypeEnum.COMMERCE:
        return "Commerce"
      default:
        return "Awaiting setup"
    }
  }, [native_type])

  return (
    <Shimmer.SmoothRender>
      <Flex style={{ width: '100%' }}>
        <FlexPad style={{ alignSelf: "flex-start" }}>
          <Avatar shape='square' src={icon_url} size={120} style={{ borderRadius: 7 }} />
        </FlexPad>
        <Flex style={{ width: '100%', }}>
          <Flex style={{ width: '100%', flexDirection: 'column' }}>
            <Flex>
              <FlexPad style={{ flex: 1, gap: 24 }}>
                <Flex style={{ flexDirection: "column" }}>
                  <AppTitle>{name}</AppTitle>
                  <AppStatus status={status} />
                </Flex>
              </FlexPad>
            </Flex>
            {
              type !== InAppTypeEnum.NATIVE ?
                <>
                  <FlexPad>
                    <ListItem>
                      <ListItemIcon>
                        <IconAppURL />
                      </ListItemIcon>
                      <div>
                        <ListItemText secondary={"URL"} />
                        <ListItemText primary={url} />
                      </div>
                    </ListItem>
                  </FlexPad>
                  <FlexPad>
                    <ListItem>
                      <ListItemIcon>
                        <IconAppURL />
                      </ListItemIcon>
                      <div>
                        <ListItemText secondary={"Testing URL"} />
                        <ListItemText primary={dev_url} />
                      </div>
                    </ListItem>
                  </FlexPad>
                </>
                :
                <FlexPad>
                  <ListItem>
                    <ListItemIcon>
                      <IconAppURL />
                    </ListItemIcon>
                    <div>
                      <ListItemText secondary={"This app is using a template"} />
                      <ListItemText primary={nativeType} />
                    </div>
                  </ListItem>
                </FlexPad>
            }
            <FlexPad>
              <ListItem>
                <ListItemIcon>
                  <IconCaption />
                </ListItemIcon>
                <div>
                  <ListItemText secondary={"Caption"} />
                  <ListItemText primary={caption} />
                </div>
              </ListItem>
            </FlexPad>
            <FlexPad>
              <ListItem>
                <ListItemIcon>
                  <IconDescription />
                </ListItemIcon>
                <div>
                  <ListItemText secondary={"Description"} />
                  <ListItemText primary={description} />
                </div>
              </ListItem>
            </FlexPad>
          </Flex>
          <Flex style={{ flexDirection: "column", width: 350 }}>
            <Capabilities capabilities={capabilities} editable={false} />
            <SupportedCountries supportedCountries={supported_countries} editable={false} />
          </Flex>
        </Flex>
      </Flex>
    </Shimmer.SmoothRender>
  )
}
