import React from 'react'
import { Invoice } from '../../types';
import { AmountCellSubtitle, AmountCellTitle, AmountCellWrapper } from '../../transactions/components/styled';

export function InvoiceAmount({ invoice }: { invoice: Invoice }) {
    const { currency, international, international_amount, amount, payer } = invoice;
    const internationalDisplay = `${payer?.currency} ${international_amount}`;
    const normalDisplay = `${currency} ${amount}`;
    return (
        <AmountCellWrapper>
        <AmountCellTitle>{international ? internationalDisplay : normalDisplay}</AmountCellTitle>
        {international && <AmountCellSubtitle>{currency} {amount}</AmountCellSubtitle>}
    </AmountCellWrapper>
    )
}
