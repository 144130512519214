import { useQuery } from "react-query";
import { _get } from "../../../utils/query";
import { statisticsApi } from "../../../utils/api";
import { Statistics } from "../../dashboard/types";

export function useAppStats(appId: string) {
    const { data, isLoading, isRefetching, error, refetch } = useQuery<Statistics>("get-app-stats"+appId, () =>
        _get(statisticsApi.getAppStatistics + '/'+appId),
        {
            enabled: !!appId,
        }
    );

    return {
        data: data || {} as Statistics,
        isLoading,
        isRefetching,
        error: error as any,
        refetch
    }
}