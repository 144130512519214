import { Upload } from "antd";
import styled from "styled-components";

export const ImageUploadWrapper = styled(Upload)`
    color: unset;

    .ant-upload-list-item-container {
        width: 120px !important;
        height: 120px !important;
    }

    .ant-tooltip {
        display: none !important;
    }

    .ant-upload-list-item {
        border-radius: 22px !important;
        border: 1px solid var(--gray700) !important;
        &:before {
            background-color: transparent !important;
        }
    }

    .ant-upload-select {
        border: 1px dashed var(--gray700) !important;
        width: 120px !important;
        height: 120px !important;
        border-radius: 22px !important;
        overflow: hidden !important;

        &:hover {
        border: 1px dashed var(--blue) !important;
        }
    }

    .ant-avatar {
        border-radius: 22px;
    }
`;

export const ImageUploadContent = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
`;