import styled from "styled-components";

export const LogoWrapper = styled.div<{ $actionable?: boolean }>`
user-select: none;
    display: flex;
    align-items: center;
    gap: var(--paddingRegular);
    ${({$actionable}) => $actionable ? `
        cursor: pointer;
    ` : ''}
`;


export const LogoImage = styled.img`
    height: 40px;
    width: 40px;
    background: white;
    border-radius: 100px;
    padding: var(--paddingRegular);
`;

export const LogoText = styled.div`
   font-family: brand;
   font-size: 28px;
`;