import styled from "styled-components";
import { ListItem } from "../../../components/listitem";

export const Flex = styled.div`
display: flex;
`;

export const BalanceWrapper = styled.div`
    height: 200px;
    width: 100%;
    background: var(--dark500);
    border-radius: var(--radiusLarge);
    padding: var(--paddingLarge);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const BalanceAmount = styled.div`
    font-size: 32px;
    font-family: bold;
`;

export const StatsPadWrapper = styled.div`
    width: 200px;
    height: 200px;
    background: var(--dark500);
    border-radius: var(--radiusLarge);
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: var(--padding);
`;

export const StatsPadTitle = styled.div`
   font-family: bold;
   font-size: 16px;
`;

export const SideItemWrapper = styled.div`
    width: 100%;
    height: 432px;
    background: var(--dark500);
    border-radius: var(--radiusLarge);
`;

export const TransactionsChartWrapper = styled.div`
    width: 100%;
    background: var(--dark500);
    border-radius: var(--radiusLarge);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 32px;
    gap: 12px;
`;

export const RecentCustomersWithSalesWrapper = styled.div`
    width: 100%;
    height: 350px;
    background: var(--dark500);
    border-radius: var(--radiusLarge);
`;

export const Title = styled.div`
    font-family: bold;
`;

export const Subtitle = styled.div`
    color: var(--gray200);
    font-size: var(--fontSmall);
`;

export const StatWrapper = styled.div`
    font-family: bold;
    display: flex;
    align-items: center;
`;

export const SideItemListItem = styled(ListItem)`
    &:hover {
        background: var(--dark400);
    }
`;