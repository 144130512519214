import { useQuery } from "react-query";
import { _get } from "../../../utils/query";
import { statisticsApi } from "../../../utils/api";
import { useAccount } from "../../auth/hooks";
import { Statistics } from "../types";

export function useAllAppsStats() {
    const {user_id} = useAccount()
    const { data, isLoading, isRefetching, error, refetch } = useQuery<Statistics>("get-statitics-across-apps", () =>
        _get(statisticsApi.getStatisticsAcrossAllApps + '/'+user_id),
        {
            enabled: !!user_id,
        }
    );

    return {
        data: data || {} as Statistics,
        isLoading,
        isRefetching,
        error: error as any,
        refetch
    }
}