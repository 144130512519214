import React, { useEffect, useState } from 'react'
import { DeviceWrapper, SettingsContent, SettingsGrid, SettingsSideContent, SettingsWrapper } from './styled'
import { ProductTypeItem, ProductTypeItemProps } from './components'
import { IconFood, IconHair, IconMakeUp, IconShirt, IconShoe } from '../../../components/icons';
import { Title } from '../styled';
import { Subtitle } from '../../dashboard/components/styled';
import { CommerceTemplateType } from './types';
import { Button } from '../../../components/button';
import { useComerceAppContext, useCreateComerceApp } from './hooks';
import { useAppDetailsContext } from '../hooks/useAppDetailsContext';
import { message } from 'antd';

export function TemplateSettingsPage() {
  const { app } = useAppDetailsContext();
  const { commerceUser, onChangeTab, refetch } = useComerceAppContext()
  const [commerceType, setProductType] = useState<CommerceTemplateType>(commerceUser?.type);
  const iconSize = 70;
  const serverSelectedProductType = !!commerceUser?.type;

  const productTypes: ProductTypeItemProps[] = [
    {
      icon: <IconFood size={iconSize} />,
      learnMoreContent: "Anything food",
      title: "Food",
      selected: commerceType === CommerceTemplateType.FOOD,
      disabled: serverSelectedProductType,
      onClick: () => serverSelectedProductType ? null : setProductType(CommerceTemplateType.FOOD)
    },
    {
      icon: <IconShoe size={iconSize} />,
      learnMoreContent: "Anything shoes",
      title: "Shoes",
      selected: commerceType === CommerceTemplateType.SHOES,
      disabled: serverSelectedProductType,
      onClick: () => setProductType(CommerceTemplateType.SHOES)
    },
    {
      icon: <IconShirt size={iconSize} />,
      learnMoreContent: "Anything shoes",
      title: "Clothing",
      selected: commerceType === CommerceTemplateType.CLOTHING,
      disabled: serverSelectedProductType,
      onClick: () => setProductType(CommerceTemplateType.CLOTHING)
    },
    {
      icon: <IconShirt size={iconSize} />,
      learnMoreContent: "Anything shoes and clothes",
      title: "Cars",
      selected: commerceType === CommerceTemplateType.CARS,
      disabled: serverSelectedProductType,
      onClick: () => setProductType(CommerceTemplateType.CARS)
    },
    {
      icon: <IconHair size={iconSize} />,
      learnMoreContent: "Anything shoes & shirts",
      title: "Hair",
      selected: commerceType === CommerceTemplateType.HAIR,
      disabled: serverSelectedProductType,
      onClick: () => setProductType(CommerceTemplateType.HAIR)
    },
    {
      icon: <IconMakeUp size={iconSize} />,
      learnMoreContent: "Anything shoes & shirts",
      title: "Phones",
      selected: commerceType === CommerceTemplateType.PHONES,
      disabled: serverSelectedProductType,
      onClick: () => setProductType(CommerceTemplateType.PHONES)
    },
  ];

  const { data, error, isLoading, onCreateApp } = useCreateComerceApp();

  const handleSubmit = () => {
    if (!commerceType) return message.error("Select a template");
    onCreateApp({
      app_id: app?.app_id,
      app_logo: app?.icon_url,
      app_name: app?.name,
      type: commerceType
    }, refetch)
  }

  useEffect(() => {
    if (error || (data as any)?.message) message.error((data as any)?.message || "An error occured. Please try again.")
  }, [error, data])

  return (
    <SettingsWrapper>
      <SettingsContent>
        <div>
          <div style={{ paddingBottom: 12 }}>
            <Title style={{ padding: 0 }}>Select template type</Title>
            <Subtitle>Template types affects what product you want to sell, data you need to send and how we display to the product to users.</Subtitle>
          </div>
          <SettingsGrid>
            {productTypes.map((a, i) => <ProductTypeItem item={a} key={i} />)}
          </SettingsGrid>
        </div>
        <div style={{ marginTop: 30 }}>
          <div>
            {
              serverSelectedProductType ?
              <Button variant='primary' onClick={() => onChangeTab("products")}>Go to products</Button>
              :
              <Button isLoading={isLoading} onClick={handleSubmit} disabled={!commerceType || serverSelectedProductType} variant='primary'>Use this template</Button>
            }
          </div>
          <div style={{ marginTop: 15, maxWidth: 600 }}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla quam eius quae numquam temporibus magnam nesciunt
            repellat veritatis nostrum esse voluptas quas fugit, minima, consectetur earum sed delectus veniam et.
          </div>
        </div>
      </SettingsContent>
      <SettingsSideContent>
        <DeviceWrapper></DeviceWrapper>
      </SettingsSideContent>
    </SettingsWrapper>
  )
}
