import { TextField } from "@mui/material";
import styled from "styled-components";

export const InputWrapper = styled(TextField)`
    * {
        border-color: var(--borderColor500) !important;
    }

    input, textarea {
        color: var(--color);
        font-family: reg;
    }

    .MuiInputLabel-root {
        color: var(--gray200) !important;
    }
    .Mui-focused {
        fieldset {
        border-color: var(--blue) !important;
    }
    }
    .MuiFormHelperText-root {
        color: var(--secondaryColor) !important;
    }
    .MuiInputBase-root::before {
        border-bottom: 1px solid var(--borderColor500) !important;
    }

    .MuiOutlinedInput-root {
        border-radius: 8px;
        height: 32px;
    }
    .Mui-disabled {
        -webkit-text-fill-color: initial !important;
    }
`;