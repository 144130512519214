import React from 'react'
import { Logger } from '../../../components/logger'
import { ReviewItem } from './ReviewItem';
import { AppReviewsContent, Title } from '../styled';
import { Button } from '../../../components/button';
import { useAppReviews } from '../hooks';
import { DataResponseView } from '../../../components/DataResponseView';

export function ReviewSection({ appId, isUpdateLoading, submitReview }: { appId: string; isUpdateLoading: boolean; submitReview: () => void }) {
    const { data, error, isLoading, refetch } = useAppReviews(appId);

    return (
        <>
            <div style={{ marginTop: 20 }}>
                <DataResponseView
                    content={
                        <>
                            <Logger
                                title={data.length > 0 ? 'You have a some to address': 'Your app is in review'}
                                subtitle={
                                    data.length > 0 ?
                                        "Resolve all issues and request for a review again."
                                        :
                                        <>
                                            Once everything is checked right, your app will be published automatically!
                                            <br />
                                            <br />
                                            This typically takes 2 hours max.
                                            <br />
                                            <br />
                                            <span style={{ fontSize: 'var(--fontSmall)', color: 'var(--gray700)' }}>You don't have to do anything here</span>
                                        </>
                                }
                            >
                                {
                                    data?.length > 0 ? (
                                        <Logger.Actions>
                                            <Button onClick={submitReview} isLoading={isUpdateLoading}>Submit for review</Button>
                                        </Logger.Actions>
                                    ) : <></>
                                }
                            </Logger>
                            {
                                data.length > 0 && (
                                    <AppReviewsContent>
                                        <Title>Feedbacks</Title>
                                        {
                                            data.map((item, index) => <ReviewItem key={index} item={item} />)
                                        }
                                    </AppReviewsContent>
                                )
                            }

                        </>
                    }
                    isLoading={isLoading}
                    data={data}
                    error={error}
                    onRetry={refetch}
                />
            </div>
        </>
    )
}
