import React from 'react'
import { SideItemListItem, SideItemWrapper, Subtitle, Title } from './styled'
import { useApps, useSelectedApp } from '../../apps/hooks'
import { ListItemIcon, ListItemText } from '@mui/material';
import { Avatar } from 'antd';
import { DataResponseView } from '../../../components/DataResponseView';
import { Button } from '../../../components/button';
import useNav from '../../../utils/hooks/useNav';
import { InApp } from '../../apps/types';
import { generatePath } from 'react-router-dom';
import { routes } from '../../../utils/routes';
import { Shimmer } from '../../../components/Shimmer';

export function SideItem() {
  const { data, isLoading, error, refetch } = useApps();
  const { setApp } = useSelectedApp()
  const { navigate } = useNav()
  const goToAppDetailsPage = (app: InApp) => {
    setApp(app);
    navigate(generatePath(routes.apps.details.root, { id: app.app_id }))
  }
  return (
    <SideItemWrapper>
     {data?.length > 0 && (
       <div style={{ padding: 12 }}>
       <Title>Apps</Title>
       <Subtitle>All available apps</Subtitle>
     </div>
     )}
      <DataResponseView
        isLoading={isLoading}
        data={data}
        error={error}
        customLoadingComponent={(
          <>
            <Shimmer.Flex style={{ padding: 12 }} $column $gap='12px'>
              <Shimmer.Flex $gap='8px'>
                <Shimmer color='var(--dark600)' width='40px' height='40px' />
                <Shimmer.Flex $column $gap='5px'>
                  <Shimmer color='var(--dark600)' width='80px' height='20px' />
                  <Shimmer color='var(--dark600)' width='150px' height='14px' />
                </Shimmer.Flex>
              </Shimmer.Flex>
              <Shimmer.Flex $gap='8px'>
                <Shimmer color='var(--dark600)' width='40px' height='40px' />
                <Shimmer.Flex $column $gap='5px'>
                  <Shimmer color='var(--dark600)' width='80px' height='20px' />
                  <Shimmer color='var(--dark600)' width='150px' height='14px' />
                </Shimmer.Flex>
              </Shimmer.Flex>
              <Shimmer.Flex $gap='8px'>
                <Shimmer color='var(--dark600)' width='40px' height='40px' />
                <Shimmer.Flex $column $gap='5px'>
                  <Shimmer color='var(--dark600)' width='80px' height='20px' />
                  <Shimmer color='var(--dark600)' width='150px' height='14px' />
                </Shimmer.Flex>
              </Shimmer.Flex>
              <Shimmer.Flex $gap='8px'>
                <Shimmer color='var(--dark600)' width='40px' height='40px' />
                <Shimmer.Flex $column $gap='5px'>
                  <Shimmer color='var(--dark600)' width='80px' height='20px' />
                  <Shimmer color='var(--dark600)' width='150px' height='14px' />
                </Shimmer.Flex>
              </Shimmer.Flex>
              <Shimmer.Flex $gap='8px'>
                <Shimmer color='var(--dark600)' width='40px' height='40px' />
                <Shimmer.Flex $column $gap='5px'>
                  <Shimmer color='var(--dark600)' width='80px' height='20px' />
                  <Shimmer color='var(--dark600)' width='150px' height='14px' />
                </Shimmer.Flex>
              </Shimmer.Flex>
            </Shimmer.Flex>
          </>
        )}
        emptyLoggerOptions={{
          title: "No apps created",
          buttonText: "Create app",
          onPress: () => navigate(routes.apps.create)
        }}
        onRetry={refetch}
        content={(
          <>
            {data?.slice(0, 5).map((app, index) => (
              <SideItemListItem onClick={() => goToAppDetailsPage(app)} style={{ gap: 8 }} key={index} bold>
                <ListItemIcon>
                  <Avatar size={40} shape='square' style={{ borderRadius: 12 }} src={app.icon_url} />
                </ListItemIcon>
                <div>
                  <ListItemText primary={app.name} />
                  <ListItemText secondary={app.caption} />
                </div>
              </SideItemListItem>
            ))}
            {
              data?.length > 0 && (
                <div style={{ paddingLeft: 12 }}>
                  <Button onClick={() => navigate(routes.apps.root)} style={{ fontSize: '12px' }}>{`${data?.length > 5 ? 'View all in apps' : 'View in apps'}`}</Button>
                </div>
              )
            }
          </>
        )}
      />
    </SideItemWrapper>
  )
}
