import styled from "styled-components";

export const HeaderMain = styled.div`
    padding: 10px;
`;

export const HeaderWrapper = styled.div`
    width: 100%;
    height: 70px;
    background: var(--dark500);
    border-radius: var(--radiusRegular);
`;

export const HeaderContent = styled.div`
    width: var(--contentWidth);
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--padding);
`;

export const HeaderActions = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--padding);
`;

export const AppButttonWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    .app-btn-first {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }
    .app-btn-second {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }
`;

export const SelectedAppWrapper = styled.div`
    position: relative;
    .selected-app-close-btn {
        display: none !important;
        transition: all 0.3s ease-in;
    }
    &:hover {
        .selected-app-close-btn {
         display: flex !important;
        }
    }
`;