import { HomeWrapper, Stack, StatsContent } from './styled'
import { BalanceItem } from './components/BalanceItem'
import { StatsPad } from './components/StatsPad'
import { SideItem } from './components/SideItem'
import { IconCustomers, IconEye, IconTransactions } from '../../components/icons'
import { TransactionsSnippet } from './components/TransactionsSnippet'
import { useAllAppsStats } from './hooks'
import { DataResponseView } from '../../components/DataResponseView'
import { Shimmer } from '../../components/Shimmer'
import useNav from '../../utils/hooks/useNav'
import { routes } from '../../utils/routes'

export function HomePage() {
    const { data, error, isLoading } = useAllAppsStats();
    const {navigate} = useNav();
    return (
        <HomeWrapper>
            <Stack $flex style={{ gap: 'var(--paddingExtraLarge)' }}>
                <DataResponseView
                    isLoading={isLoading}
                    error={error}
                    data={data}
                    customLoadingComponent={(
                        <>
                            <Stack $flex style={{ flexDirection: "column", gap: 'var(--paddingExtraLarge)' }}>
                                <Shimmer radius='var(--radiusLarge)' backgroundHeight='6000px' height='200px' width='100%' />
                                <StatsContent>
                                    <Shimmer width='200px' height='200px' radius='var(--radiusLarge)' backgroundHeight='6000px' />
                                    <Shimmer width='200px' height='200px' radius='var(--radiusLarge)' backgroundHeight='6000px' />
                                    <Shimmer width='200px' height='200px' radius='var(--radiusLarge)' backgroundHeight='6000px' />
                                </StatsContent>
                            </Stack>
                        </>
                    )}
                    content={(
                        <Stack $flex style={{ flexDirection: "column", gap: 'var(--paddingExtraLarge)' }}>
                            <BalanceItem amount={data?.overallTotalAmount} />
                            <StatsContent>
                                <StatsPad onClick={() => navigate(routes.transactions.root)} title='Total transactions' totalValue={data?.totalTransactions} id='transactionsstats' status='up' icon={<IconTransactions />} />
                                <StatsPad onClick={() => navigate(routes.invoices.root)} title='Total customers' totalValue={data?.totalCustomers} id='customersstats' status='up' icon={<IconCustomers />} />
                                <StatsPad title='Total app opens' totalValue={data?.totalOpens} id='appopensstats' status='average' icon={<IconEye />} />
                            </StatsContent>
                        </Stack>
                    )}
                />

                <Stack $flex style={{ width: 400, flexDirection: "column", gap: 'var(--paddingExtraLarge)' }}>
                    <SideItem />
                </Stack>
            </Stack>
            <TransactionsSnippet />
        </HomeWrapper>
    )
}
