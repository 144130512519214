import { useMutation } from "react-query";
import { httpPost } from "../../../../utils/query";
import { productsApi } from "../../../../utils/api";
import { ResponseStatus } from "../../../../utils/types";
import { COMMERCE_API_DOMAIN } from "../../../../utils/constants";
import { Product } from "../types";

export function useUpdateProduct() {
    const { data, isLoading, error, mutate } = useMutation<{ status: ResponseStatus }, unknown, Product>((payload: Product) =>
        httpPost(COMMERCE_API_DOMAIN + productsApi.updateProduct, payload),
    );

    return {
        isLoading,
        error: error as any,
        data,
        updateProduct: (payload: Product, onCompleted: () => void) => {
            mutate(payload, {
                onSuccess(data, variables, context) {
                    if(data.status === ResponseStatus.SUCCESS) {
                        onCompleted()
                    }
                },
            })
        }
    }
}
