import { ListItem } from "@mui/material";
import styled from "styled-components";

export const ListItemWrapper = styled(ListItem)<{$bold?: boolean; $withAction?: boolean}>`
    * {
        font-family: reg !important;
    }
    ${({$withAction}) => $withAction ? `cursor: pointer;
    &:hover {
        background: var(--dark500);
    }` : ''}
    .MuiListItemIcon-root {
        min-width: 35px;
    }
    .MuiListItemText-primary {
        font-size: var(--fontRegular);
        color: var(--color);
        ${({$bold}) => $bold ? 'font-family: bold !important;' : ''}
    }
    .MuiListItemText-secondary {
        font-size: var(--fontSmall);
        color: var(--gray200);
    }
`;