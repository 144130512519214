/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react'
import { Content } from '../../../components/content'
import { Button } from '../../../components/button'
import { Flex, FlexPad, Pad, Title } from '../styled'
import { Input } from '../../../components/input'
import { MultiUpload, useMultiUploadedFiles } from '../../../components/upload/MultiUpload'
import { Subtitle } from '../../auth/styled'
import { message, UploadFile } from 'antd'
import { InputAdornment, } from '@mui/material'
import { useComerceAppContext, useCreateProduct } from './hooks'
import { ApparelSize, CommerceTemplateType, CreateProduct, DeliveryDuration, } from './types'
import { ColorForm } from './components/ColorForm'
import { Shimmer } from '../../../components/Shimmer'

export function CreateProductPage() {
    const { commerceUser } = useComerceAppContext()
    const [name, setName] = useState('');
    const [caption, setCaption] = useState('');
    const [description, setDescription] = useState('');
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [price, setPrice] = useState('');
    const [deliveryDuration, setDeliveryDuration] = useState<DeliveryDuration>("3 hours");
    const [colors, setColors] = useState(['White']);
    const [sizes, setSizes] = useState<ApparelSize[]>(["M", "L"]);
    const { urls, clear } = useMultiUploadedFiles();
    const { isLoading, data, error, createProduct } = useCreateProduct();

    const areBasicsNOTValid = !name || !caption || !description || !price || !deliveryDuration;

    const sharedComponentApparel = (
        <>
                    <FlexPad style={{ gap: 24, flexDirection: "column" }}>
                        <Title style={{ padding: 0 }}>Sizes</Title>
                        <Flex style={{ flexWrap: "wrap", gap: 5 }}>
                            {["S", "M", "L", "XL", "XXL", "XXXL"].map((a, i) => (
                                <Pad key={i} onClick={() => {
                                    if (sizes.includes(a as any)) {
                                        const newSizes = [...sizes].filter(b => a !== b);
                                        setSizes(newSizes);
                                        return
                                    }
                                    setSizes([...sizes, a as any])
                                }} isActive={sizes.includes(a as any)}>{a}</Pad>
                            ))}
                        </Flex>
                    </FlexPad>
                    <FlexPad style={{ gap: 24, flexDirection: "column" }}>
                        <Title style={{ padding: 0 }}>Colors</Title>
                        <Flex style={{ flexWrap: "wrap", gap: 0 }}>
                            <ColorForm colors={colors} setColors={setColors} />
                        </Flex>
                    </FlexPad>
                </>
    )

    const options = {
        [CommerceTemplateType.SHOES]: {
            isValuesNOTValid: colors.length === 0 || sizes.length === 0,
            component: sharedComponentApparel
        },
        [CommerceTemplateType.CLOTHING]: {
            isValuesNOTValid: colors.length === 0 || sizes.length === 0,
            component: sharedComponentApparel
        },
        [CommerceTemplateType.FOOD]: {
            component: (<>
                <FlexPad style={{ gap: 24, flexDirection: "column" }}>
                    <Title style={{ padding: 0 }}>Calories</Title>
                    <Input placeholder='Eg: 34' />
                </FlexPad>
            </>)
        },
        [CommerceTemplateType.HAIR]: {
            component: <></>,
        },
        [CommerceTemplateType.PHONES]: {
            component: <></>,
        },
        [CommerceTemplateType.CARS]: {
            component: <></>,
        },
    }

    const OPTION_COMPONENT = options[commerceUser?.type].component;
    const OPTION_VALUES_NOT_VALID = (options[commerceUser?.type] as any).isValuesNOTValid;

    const onSubmit = () => {
        if (!urls) return;
        const payload: CreateProduct = {
            app_id: commerceUser?.app_id,
            name,
            caption,
            delivery_duration: deliveryDuration,
            description,
            images: urls,
            price: parseFloat(price),
            creator_id: commerceUser?.user_id,
            colors,
            sizes
        }
        createProduct(payload, () => {
            message.success("Product created successfully")
            setFileList([]);
            clear();
            setPrice('')
            setName('')
            setDescription('')
            setCaption('')
        })
    }

    useEffect(() => {
        if(error || (data as any)?.message) message.error((data as any)?.message || "Something happened while trying to create product.")
    }, [error, data])

    return (
        <Shimmer.SmoothTransition>
            <Content title='Add product' nested showBackButton>
                <Content.Toolbar>
                    <Button isLoading={isLoading} disabled={areBasicsNOTValid || OPTION_VALUES_NOT_VALID} onClick={onSubmit} variant='primary'>Add</Button>
                </Content.Toolbar>
                <Content.Main>
                    <Flex style={{ width: '100%' }}>
                        <Flex style={{ width: '100%', flexDirection: 'column' }}>
                            <FlexPad style={{ flexDirection: "column", overflow: "hidden", maxHeight: 200, gap: 24 }}>
                                <Flex style={{ justifyContent: "space-between", alignItems: "center" }}>
                                    <div>
                                        <Title style={{ padding: 0 }}>Images</Title>
                                        <Subtitle>Upload up to 5 product images</Subtitle>
                                    </div>
                                </Flex>
                                <MultiUpload appId={commerceUser?.app_id} fileList={fileList} setFileList={setFileList} />
                            </FlexPad>
                            <FlexPad style={{ flex: 1, gap: 24 }}>
                                <Input value={name} onChange={e => setName(e.target.value)}
                                    inputProps={{ maxLength: 30, style: { fontSize: 20, fontFamily: "bold" } }}
                                    fullWidth size='medium'
                                    label="Name"
                                    variant='standard'
                                    autoFocus
                                    helperText="Eg: Female Nike shoe"
                                />
                            </FlexPad>
                            <FlexPad style={{ flex: 1 }}>
                                <Input
                                    value={caption}
                                    onChange={(e) => setCaption(e.target.value)}
                                    inputProps={{ maxLength: 30 }}
                                    fullWidth size='medium'
                                    label="Caption"
                                    variant='standard'
                                    helperText={`Short and at max ${caption.length > 0 ? `${caption.length}/30` : '30'} characters description of your product`}
                                />
                            </FlexPad>
                            <FlexPad style={{ flex: 1 }}>
                                <Input
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    size='medium' fullWidth maxRows={30} label="Description" multiline variant='standard' helperText="Provide descriptive details of your product." />
                            </FlexPad>
                        </Flex>
                        <Flex style={{ flexDirection: "column", width: 350 }}>
                            <FlexPad style={{ gap: 24 }}>
                                <Input value={price} onChange={e => setPrice(e.target.value)}
                                    inputProps={{ maxLength: 30, style: { fontSize: 'var(--fontLarge)', fontFamily: 'bold' } }}
                                    fullWidth size='medium'
                                    label="Price"
                                    variant='standard'
                                    focused
                                    placeholder='0.00'
                                    helperText="The currency will always default to your profile country's currency"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                <Title style={{ color: "var(--color) !important", padding: 0 }}>GHS</Title>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </FlexPad>
                            {/* <FlexPad style={{ flexDirection: "column" }}>
                            <Title style={{ padding: 0 }}>Availability</Title>
                            <CompactList>
                                <ListItem>
                                    <ListItemText primary="In stock" />
                                    <Checkbox onChange={(state) => setAvailability(ProductAvailability.AVAILABLE)} checked={availability === ProductAvailability.AVAILABLE} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Out of stock" />
                                    <Checkbox onChange={(state) => setAvailability(ProductAvailability.OUT_OF_STOCK)} checked={availability === ProductAvailability.OUT_OF_STOCK} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Sold out" />
                                    <Checkbox onChange={(state) => setAvailability(ProductAvailability.SOLD_OUT)} checked={availability === ProductAvailability.SOLD_OUT} />
                                </ListItem>
                            </CompactList>
                        </FlexPad> */}
                            {OPTION_COMPONENT}
                            <FlexPad style={{ gap: 24, flexDirection: "column" }}>
                                <Title style={{ padding: 0 }}>Delivery</Title>
                                <Flex style={{ flexWrap: "wrap" }}>
                                    {["1 hour", "3 hours", "5 hours", "1 day", "2 days", "5 days", "1 week", "2 weeks", "3 weeks", "1 month", "More than 3 months"].map(a => (
                                        <Pad key={a} onClick={() => setDeliveryDuration(a as any)} isActive={deliveryDuration === a}>{a}</Pad>
                                    ))}
                                </Flex>
                            </FlexPad>
                        </Flex>
                    </Flex>
                </Content.Main>
            </Content>
        </Shimmer.SmoothTransition>
    )
}
