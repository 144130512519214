import React, { useEffect, useState } from 'react'
import { Input } from '../../../components/input'
import { validURL } from '../../../utils';
import { InputAdornment } from '@mui/material';
import { IconPassword } from '../../../components/icons';
import { Tooltip } from 'antd';

interface Props {
    appUrl: string;
    disabled?: boolean;
    setAppUrl: (text: string) => void;
}

export function UrlInput({ appUrl, disabled = false, setAppUrl }: Props) {
    const [error, setError] = useState('')

    useEffect(() => {
        setError('')
        if (appUrl) {
            const test = validURL(appUrl)
            if (!test) setError('This must be a valid URL format. Eg: "example.com". Do not include any special characters, or HTPP(s) prefix.')
        }
    }, [appUrl])

    return (
        <Input
            value={appUrl}
            onChange={(e) => setAppUrl(e.target.value)}
            error={!!error}
            disabled={disabled}
            inputProps={{
                style: { color: disabled ? 'var(--yellow600) !important' : undefined }
            }}
            InputProps={{
                endAdornment: disabled && (
                    <InputAdornment position='end'>
                        <Tooltip title="You cannot change App URLs. You can always create a new App.">
                            <div> <IconPassword color='var(--yellow600)' /></div>
                        </Tooltip>
                    </InputAdornment>
                )
            }}
            fullWidth size='medium' label="URL - Domain" variant='standard' helperText={
                <>
                    App URL is points to your optimized mobile-first website. Enter just the Domain.
                    <div>
                        For more information, please follow this <a href='https://chuttapp.com/apps' target='_blank' rel="noreferrer"> link</a>
                    </div>
                    <div style={{ color: 'var(--red600)' }}>{error}</div>
                </>
            } />
    )
}
