import React from 'react'
import { ReviewItemWrapper } from '../styled'
import { ListItem } from '../../../components/listitem'
import { ListItemIcon, ListItemText } from '@mui/material'
import { IconFeedback } from '../../../components/icons'
import { InAppReview } from '../types'

export function ReviewItem({ item }: { item: InAppReview }) {
    const { note, type, resolved } = item;
    return (
        <ReviewItemWrapper>
            <ListItem
            >
                <ListItemIcon>
                    <IconFeedback color={resolved ? 'var(--green600)' : 'var(--red600)'} />
                </ListItemIcon>
                <div style={{width: 'calc(100% - 50px)'}}>
                    <ListItemText secondary={type} style={{textTransform: 'capitalize'}} />
                    <ListItemText primary={note} />
                </div>
            </ListItem>
        </ReviewItemWrapper>
    )
}
