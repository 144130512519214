import React, { useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { routes } from '../../utils/routes'
import SignInPage from './SignInPage'
import { useAuth } from './hooks';
import useNav from '../../utils/hooks/useNav';

export function Auth() {
    const { isLoggedIn } = useAuth();
    const {navigate} = useNav();
    const {pathname} = useLocation()
  
    useEffect(() => {
      if (isLoggedIn && pathname === routes.auth.login) {
        navigate(routes.home)
      }
    }, [isLoggedIn, pathname, navigate])

  return (
    <Routes>
          <Route path={routes.auth.login} element={<SignInPage />} />
    </Routes>
  )
}
