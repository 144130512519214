import React from 'react'
import { TransferLog, TransferType } from '../types';
import { CustomerCell, DateCell, StatusCell, TableAmount, TransactionTypeItem } from './components';
import { useTransactions } from './hooks';
import { DataResponseView } from '../../../components/DataResponseView';
import { TableShimmer } from '../../../components/TableShimmer';
import { useSelectedApp } from '../../apps/hooks';
import { Table, TableProps } from '../../../components/table';
import { TitleCell } from './components/IDCell';
import useNav from '../../../utils/hooks/useNav';
import { routes } from '../../../utils/routes';
import { generatePath } from 'react-router-dom';

interface Props {
  hidePagination?: boolean;
  hideTotalCounts?: boolean;
}

export function TransactionsTable({ hidePagination, hideTotalCounts }: Props) {
  const { app } = useSelectedApp();
  const { data, error, totalCount, isLoading, isRefetching, tableParams, refetch } = useTransactions(app?.app_id);
  const { navigate } = useNav()

  const columns: TableProps<TransferLog>['columns'] = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: (_, tx) => <TitleCell onClick={() => navigate(generatePath(routes.transactions.details, {id: tx.transfer_id}))}>{tx.transfer_id.slice(0, 8)}</TitleCell>
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: () => <TransactionTypeItem type={TransferType.CREDIT} />
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (_, transfer) => <TableAmount transfer={transfer} />
    },
    {
      title: 'Customer',
      dataIndex: 'customer',
      key: 'customer',
      render: (_, transfer) => <CustomerCell sender={transfer?.sender} />
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_,) => <StatusCell status="success" />
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      width: 200,
      render: (_, transfer) => <DateCell date={transfer?.created_at} />
    },
  ];

  return (
    <DataResponseView
      data={data}
      error={error}
      isLoading={isLoading}
      customLoadingComponent={<TableShimmer />}
      content={(
        <Table
          rowKey={({transfer_id}) => transfer_id}
          columns={columns}
          dataSource={data}
          loading={isRefetching}
          totalData={totalCount}
          tableParams={tableParams}
          leaderLabel={app?.name ? `Total transactions for ${app.name}` : 'All transactions across all apps'}
          hidePagination={hidePagination}
          hideTotalCounts={hideTotalCounts}
          onRefresh={refetch}
        />
      )}
    />
  )
}
