import { useNavigate,useParams, generatePath } from 'react-router-dom'


export default function useNav() {
    const navigate = useNavigate();
    const params = useParams<any>();
    return {
        params,
        goBack: () => navigate(-1),
        navigate,
        generatePath
    }
}
