import React from 'react'
import { Content } from '../../components/content'

export function CustomerDetailsPage() {
    return (
        <Content showBackButton title='Customers'>
            <Content.Main>
                <></>
            </Content.Main>
        </Content>
    )
}
