import React from 'react'
import { StatWrapper } from './styled'
import { IconStatDown, IconStatUp } from '../../../components/icons'

export function Stat({ value, variant, color }: { value: number; variant: "success" | "error"; color?: string }) {
    return (
        <StatWrapper style={{color: color || (variant === "error" ? "var(--red600)" : "var(--green600)")}}>
            {
                variant === "success" && (
                    <>
                        <IconStatUp color='var(--green600)' />
                        {value + '%'}
                    </>
                )
            }
             {
                variant === "error" && (
                    <>
                        <IconStatDown color='var(---red600)' />
                        {value + "%"}
                    </>
                )
            }
        </StatWrapper>
    )
}
