import React, { useCallback, useEffect, useState } from 'react'
import { useAppDetailsContext } from '../hooks/useAppDetailsContext';
import { InAppTypeEnum } from '../types';
import { Logger } from '../../../components/logger';
import { Shimmer } from '../../../components/Shimmer';
import { Content, Wrapper } from './styled';
import { generatePath, Outlet, useLocation } from 'react-router-dom';
import { routes } from '../../../utils/routes';
import { Segmented } from '../../../components/segmented';
import useNav from '../../../utils/hooks/useNav';
import { useCommerceApp } from './hooks';
import { DataResponseView } from '../../../components/DataResponseView';
import { TemplateContext, TemplateDetailTab } from './types';

export function Template() {
    const { app } = useAppDetailsContext();
    const { pathname } = useLocation();
    const split = pathname.split('/');
    const tabName = split?.[(split?.length || 0) - 1]
    const inTabs = ["categories", "configuration", "products", "orders"].includes(tabName);
    const { navigate } = useNav();

    const { data, isLoading, error, isRefetching, refetch } = useCommerceApp(app?.app_id);

    const defaultSelectedTab = data?.app_id ? null : "root"

    const [selectedPage, setSelectedPage] = useState<TemplateDetailTab>(!tabName || !inTabs ? defaultSelectedTab : tabName as any);


    const onChangeTab = useCallback(
        (value: any) => {
            const routeKey: TemplateDetailTab = value === "configuration" ? "root" : value;
            setSelectedPage(routeKey);
            navigate(generatePath(routes.apps.details.template[routeKey], { id: app?.app_id }));
        },
        [app?.app_id, navigate],
    )

    useEffect(() => {
        if (data?.app_id && selectedPage === null) {
            onChangeTab("products")
        }
    }, [data, onChangeTab, selectedPage])

    if (app.type !== InAppTypeEnum.NATIVE) return (
        <Logger
            title='This view is locked for this app'
            subtitle='You created this app with a custom URL which cannot be changed now.'
        />
    );

    return (
        <Shimmer.SmoothRender>
            <Wrapper>
                <Segmented
                    value={selectedPage === "root" ? "configuration" : selectedPage}
                    style={{ marginBottom: 8 }}
                    onChange={onChangeTab}
                    options={['configuration', "products", 'orders']}
                />
                <Content style={{ marginTop: 16 }}>
                    <DataResponseView
                        content={<Outlet context={{ app, commerceUser: data, refetch, onChangeTab } as TemplateContext} />}
                        data={data}
                        isLoading={isLoading}
                        error={error}
                        isRefetching={isRefetching}
                        onRetry={refetch}
                    />
                </Content>
            </Wrapper>
        </Shimmer.SmoothRender>
    )
}
