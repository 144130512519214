import { useQuery } from "react-query";
import { InAppReview } from "../types";
import { _get } from "../../../utils/query";
import { appReviewsApi } from "../../../utils/api";
import { DataResponse } from "../../../utils/types";

export function useAppReviews(appId: string) {
    const { data, isLoading, isRefetching, error, refetch } = useQuery<DataResponse<InAppReview>>("get-app-reviews"+appId, () =>
        _get(appReviewsApi.getAll + '/'+appId),
        {
            enabled: !!appId,
        }
    );

    return {
        data: data?.data || [],
        isLoading,
        isRefetching,
        error: error as any,
        refetch
    }
}