import React from 'react'
import { Invoice } from '../types';
import { IconClose, IconDownload } from '../../../components/icons';
import { InvoiceAmount, ReceiverCell, StatusCell } from './components';
import { DateCell } from '../transactions/components';
import { MenuDropdown } from '../../../components/menu';
import { Table } from '../../../components/table';
import { TableProps } from 'antd';
import { useInvoices } from './hooks';
import { DataResponseView } from '../../../components/DataResponseView';
import { TableShimmer } from '../../../components/TableShimmer';
import { useSelectedApp } from '../../apps/hooks';

export function InvoicesTable() {
  const { app } = useSelectedApp();
  const { data, error, totalCount, isLoading, isRefetching, tableParams, refetch } = useInvoices({ appId: app?.app_id });

  const columns: TableProps<Invoice>['columns'] = [
    {
      title: 'Payer',
      dataIndex: 'payer',
      key: 'payer',
      render: (_, invoice) => <ReceiverCell receiver={invoice?.payer!} />
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (_, invoice) => <InvoiceAmount invoice={invoice} />
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_, invoice) => <StatusCell status={invoice?.status} />
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (_, invoice) => <DateCell date={invoice?.created_at} />
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      fixed: "right",
      align: "right",
      render: (_, invoice) => (
        <MenuDropdown
          items={[
            {
              key: 'download',
              label: "Download invoice",
              icon: <IconDownload />
            },
            {
              type: 'divider',
            },
            {
              key: 'cancel',
              label: "Revoke",
              icon: <IconClose color='var(--red600)' />,
              danger: true,

            },
          ]}
        />
      )
    },
  ];

  return (
    <DataResponseView
      data={data}
      error={error}
      isLoading={isLoading}
      customLoadingComponent={<TableShimmer configure={['user', 'amount', 'normal', 'date']} />}
      content={(
        <Table
          rowKey={({ transfer_id }) => transfer_id}
          columns={columns}
          dataSource={data}
          loading={isRefetching}
          totalData={totalCount}
          tableParams={tableParams}
          leaderLabel={app?.name ? `Total invoices for ${app.name}` : 'All invoices across all apps'}
          onRefresh={refetch}
        />
      )}
    />
  )
}
