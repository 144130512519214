import React, { useEffect, useMemo, useState } from 'react'
import { Logger } from '../../components/logger'
import { Button } from '../../components/button'
import { Steps, StepProps, message } from 'antd';
import { IconDeveloper, IconProduction, IconReview } from '../../components/icons';
import { Circle } from '../wallet/transactions/components/styled';
import { ReviewSection } from './components/ReviewSection';
import { Shimmer } from '../../components/Shimmer';
import { InAppStatus } from './types';
import { useUpdateAppStatus } from './hooks';
import { useAppDetailsContext } from './hooks/useAppDetailsContext';

interface StepMan extends StepProps {
    content: any;
    title: string;
}

const checkIcon = (icon: any) => <Circle className='steps-circle' style={{ background: 'var(--green10)' }}>{icon}</Circle>;
const gColor = 'var(--green600)';

export function Deployment() {
    const { appId, inAppStatus, refetch } = useAppDetailsContext();
    const parsed = useMemo(() => {
        switch (inAppStatus) {
            case InAppStatus.DEV_MODE:
                return 0
            case InAppStatus.REVIEW_PENDING:
                return 1
            case InAppStatus.PUBLISHED:
                return 2
            default:
                return 0;
        }
    }, [inAppStatus])
    const [deploymentStatus, setDeploymentStatus] = useState(parsed);
    const { error, isLoading, onChangeToReviewStatus } = useUpdateAppStatus();

    const submitReview = (resubmission?: boolean) => {
        onChangeToReviewStatus({
            app_id: appId,
            status: InAppStatus.REVIEW_PENDING
        }, () => {
            if (resubmission) message.success('Thanks for addressing the feedbacks. Your app is in review');
            else message.success("Your app is in review");
            setDeploymentStatus(1);
            refetch()
        })
    }

    useEffect(() => {
        if (error) message.error(error?.description || 'Something happened while updating status')
    }, [error])

    const steps: StepMan[] = [
        {
            title: 'In development',
            icon: deploymentStatus > -1 ? checkIcon(<IconDeveloper color={gColor} />) : <IconDeveloper />,
            content: (
                <Logger
                    title='You are currently in dev mode.'
                    subtitle={
                        <>
                            After testing your changes, request for a review. A real human will test your app and feedback(s) will be provided if necessary.
                            <br />
                            <br />
                            For more information on what factors we test against, see<br /> <a href='https://chuttapp.com/fqa?to=review-process' target='_blank' rel="noreferrer">In-App review process</a>.
                        </>
                    }
                >
                    <Logger.Actions>
                        <Button variant='primary' isLoading={isLoading} onClick={() => submitReview(true)}>Submit for review</Button>
                    </Logger.Actions>
                </Logger>
            )
        },
        {
            title: 'In review',
            icon: deploymentStatus > 0 ? checkIcon(<IconReview color={gColor} />) : <IconReview />,
            content: <ReviewSection appId={appId} isUpdateLoading={isLoading} submitReview={submitReview} />
        },
        {
            title: 'Published',
            icon: deploymentStatus > 1 ? checkIcon(<IconProduction color={gColor} />) : <IconProduction />,
            status: deploymentStatus > 1 ? "finish" : "process",
            content: (
                <Logger
                    title='App is live!'
                    subtitle='Any ChuttApp user in your supported countries will be able to search and use your app.'
                >
                </Logger>
            ),
        },
    ];

    const items: StepProps[] = steps.map((item) => ({ key: item.title, title: item.title, icon: item.icon, status: item?.status }));

    return (
        <Shimmer.SmoothRender>
            <Steps current={deploymentStatus} items={items} />
            <div>{steps[deploymentStatus].content}</div>
        </Shimmer.SmoothRender>
    )
}
